import { customElement } from 'lit/decorators.js';
import { DataCacheGeneric } from '../../../webmodule-common/cache/generic-data-cache';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { EventQuoteItemContainerAction } from '../data/events';
import { EventTemplate } from '../../../webmodule-common/other/ui/events';
import { html, nothing } from 'lit';
import { QuoteContainerManager } from '../data/quote-container';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';

export interface QuoteItemsViewOptions {
  quoteManager: QuoteContainerManager;
  userProfileCache: DataCacheGeneric;
  eventRunQuoteItemAction: EventQuoteItemContainerAction;
}

//saving buttons etc.
@customElement('wm-quoteitemsview')
export class QuoteItemsView extends ViewBase {
  protected readonly quoteManager: QuoteContainerManager;
  protected readonly eventRunQuoteItemActions: EventQuoteItemContainerAction;

  constructor(options: QuoteItemsViewOptions) {
    super();
    this.quoteManager = options.quoteManager;
    this.eventRunQuoteItemActions = options.eventRunQuoteItemAction;
  }

  public async prepareForSave() {
    throw new DevelopmentError('quote-items-view, Method not implemented.');
  }

  getValidationErrors(): string[] {
    return [];
  }

  public async refreshData(): Promise<void> {
    //this is a force reload of the data. This is not something that we want to do, if items are inuse
    //as we would end up with a bad loading of data.
  }

  public async invalidate(): Promise<void> {
    this.requestUpdate();
  }

  protected template(): EventTemplate {
    return html`${nothing}`;
  }
}
