import { consume } from '@lit/context';
import { customElement, property, state } from 'lit/decorators.js';
import { DataCacheGeneric } from '../../../webmodule-common/cache/generic-data-cache';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { emptyGuid } from '../../../webmodule-common/other/api/guid';
import { EventTemplate } from '../../../webmodule-common/other/ui/events';
import { flagInSet, isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { Frame, FrameValidationType, Snippet } from '../../../webmodule-common/interop/webmodule-interop';
import { getApiFactory } from '../../api/api-injector';
import { getFrameDetailsTemplate } from '../../frames/ui';
import {
  getV6FrameForContainer,
  getV6QuoteItemForContainer,
  isQuoteFrameVersionCurrent,
  isV6
} from '../../quotes/data/v6/helper-functions';
import { getV6QuoteItemFrameDimensions } from '../../v6config/v6-ui';
import { html, TemplateResult } from 'lit';
import { ImagePreview, imagePreviewContext } from '../../../webmodule-common/other/context/imagePreview';
import { isFrame, isSpecialItem, quoteItemThumbSrc } from '../../quotes/data/quote-helper-functions';
import { lang, tlang } from '../../../webmodule-common/other/language/lang';
import { LitTableWrapper } from '../../../webmodule-common/other/ui/littable-view';
import { moneyToTemplateResult } from '../../../webmodule-common/other/currency-formatter';
import { monitor } from '../../../webmodule-common/other/monitor';
import { PurchaseOrderContainerManager } from '../data/purchase-order-container';
import { PurchaseOrderItem, PurchaseOrderState } from '../../api/dealer-api-interface-franchisee';
import { QuoteItem } from '../../api/dealer-api-interface-quote';
import { QuoteItemContainer } from '../../quotes/data/quote-item-container';
import { RequestPage, ResultPaginated } from '../../../webmodule-common/other/ui/RequestPage';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';
import {
  WebmoduleIcon,
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

interface PurchaseOrderItemTableOptions {
  purchaseOrderManager: PurchaseOrderContainerManager;
}

interface PurchaseOrderLineItem {
  purchaseOrderItem: PurchaseOrderItem;
  qic: QuoteItemContainer;
}

@customElement('wm-purchaseorderitemtable')
export class PurchaseOrderItemTable extends LitTableWrapper<PurchaseOrderLineItem> {
  purchaseOrderManager: PurchaseOrderContainerManager;
  quoteApi = getApiFactory().quote();
  lineItems: PurchaseOrderLineItem[] = [];

  @consume({ context: imagePreviewContext })
  @property({ attribute: false })
  public imagePreview?: ImagePreview;

  @state()
  private _expandedOptions = false;

  constructor(options: PurchaseOrderItemTableOptions) {
    super();
    this.purchaseOrderManager = options.purchaseOrderManager;
  }

  override getDefaultSortAsc(): boolean {
    return false;
  }

  usePaging(): boolean {
    return false;
  }

  useInfo(): boolean {
    return true;
  }

  pageLength(): number {
    return 10000;
  }

  override getDefaultSortField(): string {
    return 'id';
  }

  getRowClass(): string {
    return 'tr';
  }

  getColumnClass(): string {
    return 'column';
  }

  override async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<PurchaseOrderLineItem>> {
    return {
      count: this.lineItems.length,
      pageCount: 1,
      pageIndex: 0,
      pageSize: this.lineItems.length,
      results: this.lineItems
    };
  }

  override getColumns(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];

    const addColumnDef = (columnId: string, column: WebModuleLitTableColumnDef) => {
      cols.push({ ...column, id: columnId, sortable: false });
    };

    addColumnDef('number', {
      title: 'Item #',
      fieldName: 'id',
      classes: 'colpx-60 colpxmax-80 order-item-row',
      displayValue: (_table: WebModuleLitTable, item: unknown, index: number) => {
        const lineItem = item as PurchaseOrderLineItem;
        return lineItem.qic.item.isRestrictedToPowerUser
          ? `${index + 1} <span class="power-user"></span>`
          : `${index + 1}`;
      }
    });

    addColumnDef('thumbnail', {
      title: 'Image',
      fieldName: 'id',
      classes: 'colpx-50 colpxmax-120 order-item-image',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const lineItem = item as PurchaseOrderLineItem;
        const quoteItem = lineItem.qic.item;

        const src =
          quoteItem.virtualThumbnailPath === ''
            ? quoteItemThumbSrc(quoteItem)
            : this.quoteApi.api.fullUrl(`api/file/${quoteItem.virtualThumbnailPath}`);

        const imagePreview = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();

          if (isFrame(quoteItem) || !isEmptyOrSpace(quoteItem.virtualThumbnailPath))
            this.imagePreview?.showImagePreview(
              this.quoteApi.api.fullUrl(`api/file/${quoteItem.virtualThumbnailPath}`),
              'quote-item-thumb'
            );
        };

        const hidePreview = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();
          this.imagePreview?.hideImagePreview();
        };

        return html` <div
          @mouseenter=${imagePreview}
          @mouseleave=${hidePreview}
          @touchstart=${imagePreview}
          @touchend=${hidePreview}
          @touchcancel=${hidePreview}
        >
          <img
            class="quote-item-thumbnail"
            alt="Thumbnail for quote item"
            src="${src}"
            style="width:48px; height:48px"
          />
        </div>`;
      }
    });

    const purchaseOrderItemTitleDisplay = (item: QuoteItem) => {
      let title = item.title;
      if (isEmptyOrSpace(title)) {
        title = tlang`%%frame-title%%`;
      }
      return html`<span class="quote-item-title">${this.htmlEncode(title)}</span><br />
        <span class="quote-item-description">${this.htmlEncode(item.description)}</span>`;
    };

    addColumnDef('title', {
      title: tlang`%%frame-title%% and Description`,
      fieldName: 'title',
      classes: 'colpx-200 order-item-title',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const lineItem = item as PurchaseOrderLineItem;
        return purchaseOrderItemTitleDisplay(lineItem.qic.item);
      }
    });

    const expandButton = html`
      <span>Options</span>
      <div>
        <webmodule-icon
          id="quote-item-expand-all-indicator"
          library="fa"
          name="fas-angles-down"
          @click=${() => (this._expandedOptions = !this._expandedOptions)}
        ></webmodule-icon>
      </div>
    `;

    addColumnDef('options', {
      title: html`${expandButton}`,
      fieldName: 'description',
      classes: 'colpx-200 colpxmax-360 order-item-description',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const lineItem = item as PurchaseOrderLineItem;
        const quoteItem = lineItem.qic.item;
        try {
          if (isFrame(quoteItem) || isSpecialItem(quoteItem))
            return this.generateQuoteItemOptionsView(lineItem, _table.isExpanded(lineItem) != undefined);
          else if (quoteItem.providerReferenceId === emptyGuid) {
            return quoteItem.description;
          } else return '';
        } catch {
          return '';
        }
      },
      click: (e: Event, table: WebModuleLitTable, item: unknown) => {
        e.stopPropagation();
        e.preventDefault();

        const rowItem = item as PurchaseOrderLineItem;
        const isShown = table.isExpanded(rowItem);

        if (isShown) table.remExtension(rowItem);
        else {
          table.addExtension(rowItem, this.getExtendedDetails(rowItem));
        }

        return true;
      }
    });

    addColumnDef('quantity', {
      title: 'Qty',
      fieldName: 'quantity',
      classes: 'colpx-50 colpxmax-100 order-item-quantity',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const lineItem = item as PurchaseOrderLineItem;
        return html`${lineItem.qic.item.quantity}`;
      }
    });

    addColumnDef('price', {
      title: 'Price',
      fieldName: 'id',
      classes: 'colpx-90 colpxmax-120 dt-right order-item-price',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const lineItem = item as PurchaseOrderLineItem;
        const price = lineItem.qic.price.supplierNettQuantityCost + lineItem.qic.price.supplierPriceAdjustment;
        return moneyToTemplateResult(price);
      }
    });

    return cols;
  }

  public isOutOfDate(): boolean {
    for (let i = 0; i < this.lineItems.length; i++) {
      const item = this.lineItems[i];
      if (isFrame(item.qic.item) || isSpecialItem(item.qic.item)) {
        const state = this.getItemValidationType(item);
        if (state === FrameValidationType.outOfDate) return true;
      }
    }
    return false;
  }

  generateQuoteItemOptionsView(lineItem: PurchaseOrderLineItem, childVisible: boolean): TemplateResult {
    const validationIndicator = () => {
      const state = this.getItemValidationType(lineItem);
      switch (state) {
        case FrameValidationType.note:
          return html`<i class="fa-solid fa-message text-primary validation-icon me-1"></i>`;
        case FrameValidationType.information:
          return html`<i class="fa-solid fa-circle-info text-primary validation-icon me-1"></i>`;
        case FrameValidationType.warning:
          return html`<i class="fa-solid fa-triangle-exclamation text-warning validation-icon me-1"></i>`;
        case FrameValidationType.critical:
          return html`<i class="fa-solid fa-circle-exclamation text-danger validation-icon me-1"></i>`;
        case FrameValidationType.outOfDate:
          return html`<i class="fa-solid fa-wrench text-danger me-1"></i>`;
      }
      return html``;
    };

    if (isSpecialItem(lineItem.qic.item) && isEmptyOrSpace(lineItem.qic.item.comment))
      return html`<span>${this.getOptionsOneLiner(lineItem)}</span
        ><span class="float-end">${validationIndicator()}</span>`;

    if (childVisible)
      return html`<span>${this.getOptionsOneLiner(lineItem)}</span
        ><span class="float-end options-dropdown">${validationIndicator()}<i class="fa-solid fa-caret-up"></i></span>`;
    else
      return html`<span>${this.getOptionsOneLiner(lineItem)}</span
        ><span class="float-end options-dropdown"
          >${validationIndicator()}<i class="fa-solid fa-caret-down"></i
        ></span>`;
  }
  getItemValidationType(lineItem: PurchaseOrderLineItem): FrameValidationType {
    //we are currently only supporting items that are for v6
    if (!isV6(lineItem.qic.item)) return FrameValidationType.nothing;

    const data = this.getFrameData(lineItem);
    if (!data) return FrameValidationType.nothing;
    const quoteItemContainer: QuoteItemContainer = lineItem.qic;

    if (
      flagInSet(this.purchaseOrderManager.purchaseOrder.state, PurchaseOrderState.Draft) &&
      !isQuoteFrameVersionCurrent(quoteItemContainer)
    )
      return FrameValidationType.outOfDate;
    return FrameValidationType.nothing;
  }

  getOptionsOneLiner(lineItem: PurchaseOrderLineItem) {
    //only proceed if this is not a frame else return the description
    if (!isV6(lineItem.qic.item)) return lineItem.qic.item.description;

    const data = getV6QuoteItemForContainer(lineItem.qic);
    return getV6QuoteItemFrameDimensions(data);
  }

  getExtendedDetails(lineItem: PurchaseOrderLineItem): TemplateResult {
    if (isFrame(lineItem.qic.item)) return this.getFrameExtendedDetails(lineItem);
    else if (isSpecialItem(lineItem.qic.item)) return this.getSpecialItemExtendedDetails(lineItem);

    return html``;
  }
  private getFrameData(lineItem: PurchaseOrderLineItem): Frame | undefined {
    if (!isV6(lineItem.qic.item)) return undefined;
    return getV6FrameForContainer(lineItem.qic);
  }

  getFrameExtendedDetails(lineItem: PurchaseOrderLineItem): TemplateResult {
    if (!isV6(lineItem.qic.item)) return html``;

    const data = this.getFrameData(lineItem);
    if (data) {
      return html` <div class="extended-options-line">${getFrameDetailsTemplate(data, true)}</div>`;
    }
    return html``;
  }

  @monitor('_expandedOptions', { delayUntilFirstUpdate: true })
  public async handleExpandCollapse() {
    if (!this.dataTable) return;

    this.lineItems.forEach(x => {
      const rowItem = x;

      const isShown = this.dataTable!.isExpanded(rowItem);

      if (this._expandedOptions && !isShown) this.dataTable!.addExtension(rowItem, this.getExtendedDetails(rowItem));
      else if (!this._expandedOptions && isShown) this.dataTable!.remExtension(rowItem);
    });

    const indicator = document.querySelector('#quote-item-expand-all-indicator') as WebmoduleIcon;

    if (this._expandedOptions) indicator.name = 'fas-angles-up';
    else indicator.name = 'fas-angles-down';
  }

  private getSpecialItemExtendedDetails(lineItem: PurchaseOrderLineItem): TemplateResult {
    if (!(isSpecialItem(lineItem.qic.item) && !isEmptyOrSpace(lineItem.qic.item.comment))) return html``;

    const warningTemplate = (): TemplateResult => {
      if (!isEmptyOrSpace(lineItem.qic.item.description)) return html``;

      return html`
        <ul class="list-group">
          <li class="list-group-item list-group-item-warning">
            <span class="me-3 fw-bold">${tlang`Reference`}</span>
            ${lang('Please provide a reference')}
          </li>
        </ul>
      `;
    };

    return html` <div class="extended-options-line">
      <div style="white-space: pre-wrap">${lineItem.qic.item.comment}</div>
      ${warningTemplate()}
    </div>`;
  }
}

export interface PurchaseOrderDetailViewOptions {
  purchaseOrderManager: PurchaseOrderContainerManager;
  purchaseOrderCache: DataCacheGeneric;
}
@customElement('wm-purchaseorderdetailview')
export class PurchaseOrderDetailView extends ViewBase {
  protected readonly purchaseOrderManager: PurchaseOrderContainerManager;
  protected table: PurchaseOrderItemTable;
  protected purchaseOrderCache: DataCacheGeneric;
  lineItems: PurchaseOrderLineItem[] = [];
  constructor(options: PurchaseOrderDetailViewOptions) {
    super();
    this.purchaseOrderManager = options.purchaseOrderManager;
    this.purchaseOrderCache = options.purchaseOrderCache;
    this.table = this.ItemTableFactory();
  }
  public isOutOfDate(): boolean {
    return this.table.isOutOfDate();
  }
  public async prepareForSave() {
    throw new DevelopmentError('items-view, Method not implemented.');
  }
  async afterConstruction(): Promise<void> {
    await this.loadLineItems();
  }
  public async loadLineItems() {
    await this.purchaseOrderManager.needsPurchaseOrder();
    const quoteApi = getApiFactory().quote();

    // NeedsPurchaseOrder will get the PO and its Items from the server.
    const itemKeys = this.purchaseOrderManager.container.items?.map(x => x.quoteItemId) ?? [];

    const quoteItemSummaries = await quoteApi.getQuoteItemsSummaryById({
      quoteItemId: itemKeys
    });
    this.lineItems = [];
    if (!quoteItemSummaries || !itemKeys) return;

    const lineItems = itemKeys.map(x => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const item = quoteItemSummaries.items.find(y => y.id == x)!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const data = quoteItemSummaries.data.find(y => y.id == x)!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const price = quoteItemSummaries.prices.find(y => y.id == x)!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const purchaseOrderItem = this.purchaseOrderManager.container.items?.find(y => y.quoteItemId == x);
      if (!purchaseOrderItem) throw new DevelopmentError('missing order item is not expected');
      const lineItem: PurchaseOrderLineItem = {
        purchaseOrderItem,
        qic: { item, data, price }
      };
      return lineItem;
    });

    const result = lineItems.filter(Boolean);
    this.lineItems = result;
    this.table.lineItems = result;
  }
  public getValidationErrors(): string[] {
    return [];
  }

  public async refreshData(): Promise<void> {
    //this is a force reload of the data. This is not something that we want to do, if items are inuse
    //as we would end up with a bad loading of data.
    await this.loadLineItems();
    await this.table.refreshData();
    this.requestUpdate();
  }

  public async invalidate(): Promise<void> {
    this.requestUpdate();
    this.table.refreshData();
  }

  public buttonMenu(): Snippet {
    return html``;
  }

  protected ItemTableFactory(): PurchaseOrderItemTable {
    return new PurchaseOrderItemTable({
      purchaseOrderManager: this.purchaseOrderManager
    });
  }

  protected template(): EventTemplate {
    return html` <div>${this.table}</div>`;
  }
}
