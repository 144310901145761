/**
 * This is the calculation engine for the NFRC component of Dealer, which is being called the nfrc-standard-v1
 * this is based on the information and specification oringinally provided by USA customer Awake
 * new calculation engines should be created for any new need and variation, and registered in configuration as the
 * calculation engine of choice for the deployment
 */

import { nfrc_Standard_v1, NFRCCalculation, NFRCCalculationResult } from './nfrc-calculation';
import {
  NFRCItemValues,
  NFRCRowResult,
  NFRCRowValues,
  NFRCValue
} from '../../webmodule-common/interop/webmodule-interop';

function sameText(a: string, b: string): boolean {
  return a.toLowerCase() === b.toLowerCase();
}

const nfrcCol_UFactor = 'U-FACTOR';
const nfrcCol_SHGC = 'SHGC';
const nfrcCol_FrameArea = 'FRAME AREA';
const nfrcOutput_ufactor = 'Weighted Avg U-Factor';
const nfrcOutput_shgc = 'Weighted Avg SHGC';
export class NFRCStandardv1Calculation implements NFRCCalculation {
  name: string = nfrc_Standard_v1;
  requiredPublishedCodes: string[] = [nfrcCol_UFactor, nfrcCol_SHGC, nfrcCol_FrameArea];
  //this is the list of outputs availble to nfrcColumnCalculation
  //which means these can be used as NFRCOption Columns
  outputFieldNames: string[] = [nfrcCol_UFactor, nfrcCol_SHGC];

  /**
   * calculates nfrc value and any other relevant calculations and returns a named list of results
   * nfrc =Sum( FrameArea * UFactor  ) / Sum(FrameArea)
   * the above calculation uses all frame data from all rows
   * @param items the list of all frame configurations on the quote for calculating
   * @returns
   */
  nfrcCalculation(items: NFRCRowValues[]): NFRCCalculationResult {
    let sumOfUFactorxFrameArea = 0;
    let sumOfSHGCxFrameArea = 0;
    let sumOfFrameArea = 0;

    //loop each row in the data set
    items.forEach(rowItem => {
      //loop each frame in the row
      rowItem.nfrcData.forEach(rowChildItem => {
        //these will always be found, because the data has been pre-vetted
        const uFactor = rowChildItem.values.find(x => sameText(x.name, nfrcCol_UFactor))?.value;
        const shgc = rowChildItem.values.find(x => sameText(x.name, nfrcCol_SHGC))?.value;
        const frameArea = rowChildItem.values.find(x => sameText(x.name, nfrcCol_FrameArea))?.value;
        if (uFactor !== undefined && frameArea !== undefined && shgc !== undefined) {
          sumOfUFactorxFrameArea += uFactor * frameArea;
          sumOfSHGCxFrameArea += shgc * frameArea;
          sumOfFrameArea += frameArea;
        }
      });
    });
    const weightedUFactor = sumOfFrameArea === 0 ? 0 : sumOfUFactorxFrameArea / sumOfFrameArea;
    const weightedSHGC = sumOfFrameArea === 0 ? 0 : sumOfSHGCxFrameArea / sumOfFrameArea;

    return {
      values: [
        {
          name: nfrcOutput_ufactor,
          value: weightedUFactor
        },
        {
          name: nfrcOutput_shgc,
          value: weightedSHGC
        }
      ]
    };
  }
  private nfrcGetColData(publishedCode: string, nfrcData: NFRCItemValues[]): number[] {
    const result: number[] = [];
    nfrcData.forEach(itemValue => {
      const codeValue = itemValue.values.find(value => sameText(value.name, publishedCode));
      if (codeValue) result.push(codeValue.value);
    });
    return result;
  }

  /**
   *
   * @param items this is the collected data from a quote item frame and all its nested frames at a frameconfigurator level
   * @returns a collection of column result values determined by the calculation
   */
  nfrcColumnCalculation(items: NFRCRowValues): NFRCRowResult {
    const values: NFRCValue[] = [];

    this.outputFieldNames.forEach(fieldCode => {
      const columnData = this.nfrcGetColData(fieldCode, items.nfrcData);
      if (columnData.length === 0) return;
      //get the avg value of all values and set that as the value for this column
      const value = columnData.reduce((sum, num) => sum + num, 0) / columnData.length;
      const nfrcValue: NFRCValue = { name: fieldCode, value };
      values.push(nfrcValue);
    });

    return { id: items.id, values };
  }
}
