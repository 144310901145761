import { DevelopmentError } from '../../webmodule-common/other/development-error';
import { QuotePriceCalculation } from './dealer-api-interface-quote';
import { tlang } from '../../webmodule-common/other/language/lang';

export function QuotePriceCalculationToStr(value: QuotePriceCalculation): string {
  switch (value) {
    case QuotePriceCalculation.Margin:
      return tlang`Margin`;
    case QuotePriceCalculation.Markup:
      return tlang`Markup`;
    default:
      throw new DevelopmentError(`${QuotePriceCalculation[value]} does not have a translation`);
  }
}
