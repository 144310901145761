import { EventGetReference, EventSnippet } from '../../../webmodule-common/other/ui/events';
import { getApiFactory } from '../../api/api-injector';
import { getPurchaseOrderStatus } from '../data/purchase-order-helper-functions';
import { html, TemplateResult } from 'lit';
import { IStaleData } from '../../../webmodule-common/other/stale-data';
import { LitTableWrapper } from '../../../webmodule-common/other/ui/littable-view';
import { moneyToTemplateResult } from '../../../webmodule-common/other/currency-formatter';
import { PurchaseOrder, PurchaseOrderState, ResultGetPurchaseOrders } from '../../api/dealer-api-interface-franchisee';
import { PurchaseOrderApi } from '../../api/purchase-order-api';
import { RequestPage, ResultPaginated } from '../../../webmodule-common/other/ui/RequestPage';
import { resolveURL } from '../../../webmodule-common/other/ui/resource-resolver';
import { resourcePurchaseOrder } from '../ui/launcher';
import { tlang } from '../../../webmodule-common/other/language/lang';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

export type EventPurchaseOrderOpen = (purchaseOrder: PurchaseOrder) => Promise<void>;

export interface PurchaseOrderSummaryTableOptions {
  purchaseOrderState?: PurchaseOrderState;
  numberOfDaysHistory?: number;
  title: EventSnippet;
  purchaseOrderOwnerId: EventGetReference;
  pageFragment: string;
  stale?: IStaleData;
}

export class PurchaseOrderSummaryTable extends LitTableWrapper<PurchaseOrder> {
  eventTitle: EventSnippet;
  purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();
  purchaseOrderOwnerId: EventGetReference;
  purchaseOrderState: PurchaseOrderState;
  titleFilter: string | null;
  numberOfDaysHistory: number | undefined;
  pageFragment: string;
  staleData: IStaleData;
  dataIsStale = false;
  constructor(options: PurchaseOrderSummaryTableOptions) {
    super();
    this.staleData = options.stale ?? { exists: () => false };
    this.purchaseOrderState = options.purchaseOrderState ?? PurchaseOrderState.None;
    this.eventTitle = options.title;
    this.purchaseOrderOwnerId = options.purchaseOrderOwnerId;
    this.titleFilter = null;
    this.numberOfDaysHistory = options.numberOfDaysHistory;
    this.pageFragment = options.pageFragment;
  }
  isDataLoadDelayed() {
    return true;
  }

  override enableFiltering(): boolean {
    return true;
  }

  override updateFilter(_searchTerm: string | null) {
    this.titleFilter = _searchTerm;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<PurchaseOrder>> {
    const results = await this.purchaseOrderApi.getPurchaseOrders({
      purchaseOrderOwnerId: await this.purchaseOrderOwnerId(),
      statesToLoad: this.purchaseOrderState,
      numberOfDaysHistory: this.numberOfDaysHistory ?? null,
      filter: this.titleFilter,
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      sortField: request.sortField,
      sortAsc: request.sortAsc,
      createdById: null
    });

    if (!results) {
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };
    }

    await this.doPreFetching(results);

    return results.purchaseOrders;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`System %%purchase-order-abrev%% No.`,
        fieldName: 'purchaseOrderNumber',
        sortable: true,
        classes: 'colpx-90 purchase-order-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return this.getPurchaseOrderLink(
            purchaseOrder,
            purchaseOrder.purchaseOrderNumber == 0 ? 'N/A' : purchaseOrder.purchaseOrderNumber.toString()
          );
        }
      },
      {
        title: tlang`%%franchisee%% %%purchase-order-abrev%% No.`,
        fieldName: 'customPurchaseOrderNumber',
        classes: 'colpx-90 purchase-order-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return html`${purchaseOrder.customPurchaseOrderNumber}`;
        }
      },
      {
        title: tlang`%%purchase-order%% Title`,
        fieldName: 'title',
        sortable: true,
        classes: 'colpx-90 purchase-order-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return this.getPurchaseOrderLink(purchaseOrder, purchaseOrder.title);
        }
      },
      {
        title: tlang`Status`,
        fieldName: 'state',
        classes: 'colpx-90 purchase-order-state no-pseudo',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return getPurchaseOrderStatus(purchaseOrder.state, true);
        }
      },
      {
        title: tlang`Amount`,
        fieldName: 'calculatedNetTotal',
        classes: 'colpx-90 dt-right purchase-order-total no-pseudo',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return moneyToTemplateResult(purchaseOrder.calculatedNetTotal + purchaseOrder.calculatedAdjustmentTotal);
        }
      }
    ];
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  getDefaultSortField(): string {
    return 'purchaseOrderNumber';
  }

  protected getPreFetched(_results: ResultGetPurchaseOrders): Promise<void>[] {
    return [];
  }

  protected getPurchaseOrderLink(row: PurchaseOrder, value: string): TemplateResult {
    return html`<a
      class="purchase-order-link"
      href="${resolveURL(resourcePurchaseOrder, row.id)}"
      data-purchaseorderid="${row.id}"
      >${this.htmlEncode(value)}</a
    >`;
  }

  protected async doPreFetching(results: ResultGetPurchaseOrders) {
    await Promise.all(this.getPreFetched(results));
  }
}
