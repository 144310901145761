import { addDynamicMoney, QuoteItemPriceAdjustment } from './quote-item-price-adjust-view';
import { applyV6PricesToQuoteItemPrice, isEmptyObject } from '../data/v6/helper-functions';
import { customElement } from 'lit/decorators.js';
import { DynamicValueBinder, EventValue, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventTemplate } from '../../../webmodule-common/other/ui/events';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html } from 'lit';
import { money } from '../../../webmodule-common/other/currency-formatter';
import { PricingStrategy } from './pricing-strategy';
import { QuoteFrameItemPrice } from '../../../webmodule-common/interop/webmodule-interop';
import { QuoteItemPrice, QuotePrice, QuotePriceCalculation } from '../../api/dealer-api-interface-quote';
import { StockLookupViewExtra } from '../data/quote-container';
import { tlang } from '../../../webmodule-common/other/language/lang';

@customElement('wm-quoteitempriceadjustmentsupplier')
export class QuoteItemPriceAdjustmentSupplier extends QuoteItemPriceAdjustment {
  private buyInValue: number | null = null;
  private _showExtendedlistPrice: boolean;
  private _showMultiplier: boolean;
  private _showSupplierPriceAdjustment: boolean;

  constructor(
    quotePrice: QuotePrice,
    quoteItemPrice: QuoteItemPrice,
    quantity: number,
    forceReadonly: boolean,
    buyInCost: StockLookupViewExtra[] | undefined,
    showExtendedListPrice: boolean,
    showMultiplier: boolean,
    showSupplierPriceAdjustment: boolean
  ) {
    super(quotePrice, quoteItemPrice, quantity, forceReadonly, buyInCost);
    this._showExtendedlistPrice = showExtendedListPrice;
    this._showMultiplier = showMultiplier;
    this._showSupplierPriceAdjustment = showSupplierPriceAdjustment;

    addDynamicMoney(
      this.dataTracker,
      'extendedListPrice',
      () => this.quoteItemPrice.supplierGrossQuantityCost,
      (_value: EventValue) => {
        console.log('extendedListPrice readonly');
      },
      () => true
    );

    // Discount/multiplier = supplierGrossQuantityCost - supplierNettQuantityCost
    // This value is to be "subtracted" from the list price and thus multiplied by -1 to show that it is subtracted
    // This binding is not used, only for display purposes.
    addDynamicMoney(
      this.dataTracker,
      'multiplier',
      () => (this.quoteItemPrice.supplierGrossQuantityCost - this.quoteItemPrice.supplierNettQuantityCost) * -1,
      (_value: EventValue) => {
        console.log('multiplier readonly');
      },
      () => true
    );

    addDynamicMoney(
      this.dataTracker,
      'supplierCost',
      () => this.quoteItemPrice.quantityCost,
      (_value: EventValue) => {
        console.log('suppilerCost readonly');
      },
      () => true
    );

    this.dataTracker.addObjectBinding(
      () => this.quoteItemPrice,
      'supplierPriceAdjustment',
      'supplierPriceAdjustment',
      FieldType.money,
      false
    );
  }

  override async afterConstruction(): Promise<void> {
    if (this.buyInCost && this.buyInCost.length > 0) {
      this.buyInValue = this.buyInCost.reduce((total, item) => total + (item.cost ?? 0), 0);
      const field = this.getBuyInFieldName();

      this.dataTracker.addBinding(
        new DynamicValueBinder(
          FieldType.money,
          false,
          () => {
            return this.buyInValue;
          },
          _value => {
            //Read-Only value
          },
          () => true
        ),
        field,
        field,
        FieldType.money,
        false
      );
    }
  }

  getMultiplierLabel(): string {
    return tlang`Multiplier (${(
      this.quoteItemPrice.supplierNettQuantityCost /
      (this.quoteItemPrice.supplierGrossQuantityCost + (this.quoteItemPrice.supplierGrossQuantityCost == 0 ? 1 : 0))
    ).toFixed(4)})`;
  }

  protected eventKeyupSupplierCost = (e: CustomEvent<KeyboardEvent>) => {
    if (e.detail.code === 'Enter') {
      this.eventChangeSupplierCost();
    }
  };

  protected eventChangeSupplierCost = () => {
    const p = this.quoteItemPrice;

    const supplierFields = (this.dataTracker.getEditorValue('supplierCost') as number) ?? 0;
    const value = supplierFields - (p.supplierNettQuantityCost + (this.buyInValue ?? 0));

    this.processSupplierPriceAdjustment(value);
  };
  protected eventKeyupSupplierPriceAdjustment = (e: CustomEvent<KeyboardEvent>) => {
    if (e.detail.code === 'Enter') {
      this.eventChangeSupplierPriceAdjustment();
    }
  };

  protected eventChangeSupplierPriceAdjustment = () => {
    const value = (this.dataTracker.getEditorValue('supplierPriceAdjustment') as number) ?? 0;

    this.processSupplierPriceAdjustment(value);
  };

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
    const isSupplier = this.claims.isAgent;

    return html`
      <form class="line-item-price-adjustment form-one-col">
        <div class="row">
          <h2>${tlang`%%supplier%%`}</h2>
          <div class="form-column">
            ${forms.intReadonly('quantity', tlang`Quantity`)}
            ${this._showExtendedlistPrice || isSupplier
              ? forms.moneyReadonly(
                  'extendedListPrice',
                  tlang`Extended List Price`,
                  tlang`The Extended List Price is Quantity multiplied by Item List Price`
                )
              : forms.textHidden('extendedListPrice', tlang`Extended List Price`)}
            ${this._showMultiplier || isSupplier
              ? forms.moneyReadonly('multiplier', this.getMultiplierLabel())
              : forms.textHidden('multiplier', this.getMultiplierLabel())}
            ${!isSupplier
              ? this._showSupplierPriceAdjustment
                ? forms.moneyReadonly('supplierPriceAdjustment', tlang`Price Adjustment`)
                : forms.textHidden('supplierPriceAdjustment', tlang`Price Adjustment`)
              : forms.money('supplierPriceAdjustment', tlang`Price Adjustment`, {
                  events: {
                    blur: this.eventChangeSupplierPriceAdjustment,
                    keyup: this.eventKeyupSupplierPriceAdjustment
                  }
                })}
            ${this.buyInValue ? forms.moneyReadonly(this.getBuyInFieldName(), tlang`Buy-In Items`) : html``}
            ${!isSupplier
              ? forms.moneyReadonly('supplierCost', tlang`Cost Price`)
              : forms.money('supplierCost', tlang`Cost Price`, {
                  events: {
                    blur: this.eventChangeSupplierCost,
                    keyup: this.eventKeyupSupplierCost
                  }
                })}
          </div>

          <h2>${tlang`%%franchisee%%`}</h2>
          <div class="form-column">
            ${forms.float('percentMarginOrMarkup', this.getMarginLabel())}
            ${forms.moneyReadonly('subTotal', tlang`Sub Total`)}
            ${forms.money('priceAdjustment', tlang`Price Adjustment`, {
              events: {
                blur: this.eventChangePriceAdjustment,
                keyup: this.eventKeyupPriceAdjustment
              }
            })}
            ${forms.money('calculatedGrossSellingPrice', tlang`Sell Price`, {
              events: {
                blur: this.eventChangeGrossSellingPrice,
                keyup: this.eventKeyupGrossSellingPrice
              }
            })}
          </div>
        </div>
      </form>
    `;
  }

  private processSupplierPriceAdjustment(value: number) {
    const p = this.quoteItemPrice;
    const frameItemPrice = p.sourceData as unknown as QuoteFrameItemPrice;
    if (p.supplierPriceAdjustment === value) return;
    p.supplierPriceAdjustment = value;

    if (!isEmptyObject(frameItemPrice)) applyV6PricesToQuoteItemPrice(this.quantity, frameItemPrice, p, this.buyInCost);

    p.calculatedNetSellingPrice = money(
      this.quotePrice.calculation == QuotePriceCalculation.Margin
        ? PricingStrategy.Margin.getPriceWithAdjustment(
            p.quantityCost,
            p.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup,
            undefined
          )
        : PricingStrategy.Markup.getPriceWithAdjustment(
            p.quantityCost,
            p.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup,
            undefined
          ),
      2
    );

    p.calculatedGrossSellingPrice = money(p.calculatedNetSellingPrice + p.priceAdjustment, 2);

    this.requestUpdate();
  }

  private getBuyInFieldName(): string {
    return `taxRate_rate_${this.quoteItemPrice.id}`;
  }
}
