// eslint-disable-next-line import/named
import * as luxon from 'luxon';
import { ClientApi } from '../../api/client-api';
import { customElement } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getApiFactory } from '../../api/api-injector';
import { html } from 'lit';
import { IQuoteValidator, QuoteContainerManager } from '../data/quote-container';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { Quote, QuoteState, QuoteType } from '../../api/dealer-api-interface-quote';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';

export interface QuoteDetailViewOptions {
  quoteManager: QuoteContainerManager;
}

@customElement('wm-quotedetailview')
export class QuoteDetailView extends ViewBase implements IQuoteValidator {
  clientApi: ClientApi = getApiFactory().client();
  quoteManager: QuoteContainerManager;
  dataBinding: DataBinding;
  dataTracker: DataTracker;

  constructor(options: QuoteDetailViewOptions) {
    super();
    this.quoteManager = options.quoteManager;

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `quote-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.quote),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('title');
    addField('customQuoteNumber');
    addField('budget', FieldType.money, true);
    addField('description');
    addField('quoteType', FieldType.int);
    addField('dateCreated', FieldType.dateTime);
    addField('lastModifiedDate', FieldType.dateTime);
    addField('installationDate', FieldType.date, true);
  }

  get quote(): Quote {
    return this.quoteManager.quote;
  }

  public getValidationErrors() {
    return this.getValidationErrorsForState(this.quote.state);
  }

  public getValidationErrorsForState(state: QuoteState) {
    const errors: string[] = [];

    if (state != QuoteState.Draft) {
      if (isEmptyOrSpace(this.quote.title)) errors.push(tlang`Please provide a %%quote%% Title`);
    }

    if (state == QuoteState.Draft || state == QuoteState.Active) {
      if (this.quote.validUntilDate && luxon.DateTime.fromISO(this.quote.validUntilDate) < luxon.DateTime.now()) {
        errors.push(tlang`'Valid to' Date must be a date in the future not in the past.`);
      }

      if (this.quote.installationDate && luxon.DateTime.fromISO(this.quote.installationDate) < luxon.DateTime.now()) {
        errors.push(tlang`'Installation Date' must be a date in the future not in the past.`);
      }
    }

    return errors;
  }

  public async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }

  public async loadOrRefresh(): Promise<void> {
    await this.quoteManager.needsQuote();
    this.requestUpdate();
  }

  public buttonMenu(): Snippet {
    return html``;
  }

  async valid(_state: QuoteState): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  protected template(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker);
    return html`
      <form class="py-3 px-0 frm-quote-details form-two-col">
        <div class="row">
          <div>
            ${forms.text('title', tlang`%%quote%% Title`, 200)} ${forms.intReadonly('quoteNumber', tlang`%%quote%% No`)}
            ${forms.float('budget', tlang`%%quote%% Budget`)}
            ${forms.note('description', tlang`%%quote%% Description`, 3000)}
          </div>

          <div>
            ${forms.text('customQuoteNumber', tlang`Internal Order No.`, 200)}
            ${forms.enumPicker('state', QuoteState, tlang`Status`)}
            ${forms.radioGroup('quoteType', QuoteType, tlang`Type`, true)}
            ${forms.dateReadonly('dateCreated', tlang`Created`)}
            ${forms.dateReadonly('lastModifiedDate', tlang`Last Modified`)}
            ${forms.date('installationDate', tlang`Installation Date`, 'date')}
          </div>
        </div>
      </form>
    `;
  }
}
