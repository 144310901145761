// eslint-disable-next-line import/named
import { asMarkdownTemplate } from '../../../webmodule-common/other/general/markdown';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { fromJsonStr } from '../../../webmodule-common/other/blob-converters';
import { html } from 'lit';
import { information } from '../../../webmodule-common/other/ui/modal-option';
import { isFrame } from '../data/quote-helper-functions';
import { isValidV6ConfigVersion, v6Editors } from '../../v6config/v6config';
import {
  QuoteDefaultsDataProvider,
  QuoteFrameItemMarker,
  ResultQuoteFrameItemUpdated,
  V6QuoteDefaultsEditor
} from '../../../webmodule-common/interop/webmodule-interop';
import { QuoteState } from '../../api/dealer-api-interface-quote';
import { QuoteSupplierDefaultsView, QuoteSupplierDefaultsViewOptions } from '../views/quote-supplier-defaults.view';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { updateQuoteItemContainer } from '../data/v6/helper-functions';
import { isSupplierAgent } from '../../../webmodule-common/other/currentuser-claims';
import { flagInSet } from '../../../webmodule-common/other/ui/string-helper-functions';

export class V6QuoteSupplierDefaultsView extends QuoteSupplierDefaultsView implements QuoteDefaultsDataProvider {
  v6editor: V6QuoteDefaultsEditor | null;

  constructor(options: QuoteSupplierDefaultsViewOptions) {
    super(options);
    this.v6editor = isValidV6ConfigVersion() ? v6Editors().getQuoteDefaultsEditorService(this) : null;
  }

  get supplierReferenceOverrideKey(): string {
    return this.quoteManager.quote.quoteOwnerId;
  }

  get readonly(): boolean {
    return this.v6editor === null || this.quoteManager.isReadonly();
  }

  get quoteIGUs(): unknown {
    return this.getQuoteIGUs();
  }

  private get hasSupplierPriceAdjustments(): boolean {
    return this.quoteManager.container.itemPrices?.some(x => x.supplierPriceAdjustment !== 0) ?? false;
  }

  async updateQuoteItemsBegin(): Promise<boolean> {
    return true;
  }

  applyQuoteDefaultsHeaderTemplate() {
    const explanation = tlang`${'ref:WI221950-QuoteView-DiscardSupplierPriceAdjustmentOnGlobalOptionsChange:markdown'}
      ## Information

      Global option changes may result in changes to items with supplier price adjustments
      <webmodule-icon library="fa" name="fas-tags" class="text-base"></webmodule-icon>, if so, these adjustments will be discarded.
      You will then need to reactivate the associated support ticket to request a supplier review.`;

    return this.hasSupplierPriceAdjustments
      ? html` <div class="alert alert-info m-3" role="alert">${asMarkdownTemplate(explanation)}</div>`
      : undefined;
  }

  async updateQuoteItemsEnd(updatedCount: number): Promise<void> {
    if (updatedCount > 0) {
      await this.quoteManager.runAndSaveSSI();
    }
  }

  async dispose(): Promise<void> {
    await super.dispose();
    //add nullable to deal with version missing info
    if (this.v6editor) this.v6editor.ui.remove();
    this.v6editor?.releaseResources?.();
    this.v6editor = null;
  }

  async quoteItems(): Promise<QuoteFrameItemMarker[]> {
    await this.quoteManager.needsQuoteItems();
    const frames = this.quoteManager.container.items
      ?.filter(x => isFrame(x))
      .map(x => {
        const container = this.quoteManager.quoteItemContainer(x.id);
        const qfd: QuoteFrameItemMarker = {
          id: x.id,
          description: container.item.title,
          qty: container.item.quantity,
          position: this.quoteManager.itemPosition(x.id) ?? -1,
          quoteItem: fromJsonStr(container.data?.providerData)
        };
        return qfd;
      });
    return frames ?? [];
  }

  async saveItem(frameData: ResultQuoteFrameItemUpdated): Promise<boolean> {
    const container = this.quoteManager.quoteItemContainer(frameData.id);
    const dataVer = container.data?.recordVersion ?? '';

    const buyInCost = await this.quoteManager.getBuyInCosts(frameData.quoteItem['buyIn']);

    updateQuoteItemContainer(container, frameData.quoteItem, frameData.price, buyInCost);
    const newContainer = await this.quoteManager.saveAndUpdateQuoteItem(container, frameData.thumbnail ?? '');
    return dataVer !== newContainer.data?.recordVersion ?? '';
  }

  getQuoteDefaults(): unknown {
    return this.getQuoteDefaultOptions();
  }

  saveQuoteDefaults(quoteDefaultsData: unknown): boolean {
    this.setQuoteDefaultOptions(quoteDefaultsData);
    return true;
  }

  async prepareForSave() {
    await this.v6editor?.prepareForSave();
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    //override to do any asyn post construction work
  }

  buttonMenu(): Snippet {
    const resetEvent = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      await this.v6editor?.resetQuoteDefaults();
    };
    const applyToItems = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.areItemsOpen()) {
        await information(tlang`Please close any open !!quote-item!! first`);
        return;
      }

      await this.prepareForSave();
      if (this.quoteManager.changed()) await this.quoteManager.saveQuote();
      await this.v6editor?.applyQuoteDefaultsToItems();
    };
    return this.v6editor
      ? html`
          <webmodule-button size="small" variant="default" @click=${applyToItems} ?disabled=${!this.canModify()}>
            ${tlang`Apply To All Existing !!frame!!`}
          </webmodule-button>
          <webmodule-button size="small" variant="default" @click=${resetEvent} ?disabled=${!this.canModify()}>
            ${tlang`Reset
            Defaults`}
          </webmodule-button>
        `
      : html``;
  }

  protected getQuoteIGUs(): unknown {
    //TODO, get these from the quote manager
    return [];
  }

  protected canModify() {
    return (
      this.quote.state === QuoteState.Draft ||
      this.quote.state === QuoteState.Active ||
      (isSupplierAgent() &&
        // eslint-disable-next-line no-bitwise
        flagInSet(this.quote.state, QuoteState.Accepted_AssignedToReviewer | QuoteState.SupplierReviewPending))
    );
  }

  protected getQuoteDefaultOptions(): unknown | null {
    return null;
  }

  protected setQuoteDefaultOptions(_options: unknown) {
    throw new DevelopmentError('setQuoteDefaultOptions must override');
  }

  protected template(): EventTemplate {
    return this.v6editor ? html`${this.v6editor?.ui}` : html`${tlang`%%supplier%% service not available`}`;
  }
}
