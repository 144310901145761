import { checkValidations, inRange } from '../../../webmodule-common/other/ui/data-entry-screen-helpers';
import { clone, compare } from '../../../webmodule-common/other/clone';
import { currentUserClaims } from '../../../webmodule-common/other/currentuser-claims';
import { customElement } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import {
  DataTracker,
  DynamicValueBinder,
  EventValue,
  EventValueGetter,
  EventValueSetter,
  FieldType
} from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html, TemplateResult } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { ModalDialog } from '../../../webmodule-common/other/ui/modal-base';
import { money } from '../../../webmodule-common/other/currency-formatter';
import { PricingStrategy } from './pricing-strategy';
import { QuoteItemPrice, QuotePrice, QuotePriceCalculation } from '../../api/dealer-api-interface-quote';
import { StockLookupViewExtra } from '../data/quote-container';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { QuotePriceCalculationToStr } from '../../api/enum-to-text';

export const addDynamicMoney = (
  dataTracker: DataTracker,
  fieldName: string,
  getter: EventValueGetter,
  setter: EventValueSetter,
  readonly?: () => boolean
) => {
  dataTracker.addBinding(
    new DynamicValueBinder(FieldType.money, true, getter, setter, readonly),
    fieldName,
    fieldName,
    FieldType.money,
    true
  );
};

export const addDynamicFloat = (
  dataTracker: DataTracker,
  fieldName: string,
  getter: EventValueGetter,
  setter: EventValueSetter,
  readonly?: () => boolean
) => {
  dataTracker.addBinding(
    new DynamicValueBinder(FieldType.float, true, getter, setter, readonly),
    fieldName,
    fieldName,
    FieldType.float,
    true
  );
};

@customElement('wm-quoteitempriceadjustment')
export class QuoteItemPriceAdjustment extends ModalDialog {
  public ok = false;
  protected quoteItemPrice: QuoteItemPrice;
  protected dataTracker: DataTracker;
  protected quotePrice: QuotePrice;
  protected originalQuoteItemPrice: QuoteItemPrice;
  protected forceReadonly: boolean;
  protected quantity: number;
  protected buyInCost: StockLookupViewExtra[] | undefined;
  protected claims = currentUserClaims();

  constructor(
    quotePrice: QuotePrice,
    quoteItemPrice: QuoteItemPrice,
    quantity: number,
    forceReadonly: boolean,
    buyInCost: StockLookupViewExtra[] | undefined
  ) {
    super();
    this.forceReadonly = forceReadonly;
    this.quantity = quantity;
    this.quotePrice = quotePrice;
    this.buyInCost = buyInCost;
    this.quoteItemPrice = clone(quoteItemPrice);
    this.originalQuoteItemPrice = quoteItemPrice;
    this.dataTracker = new DataTracker(new DataBinding(this.ui));

    addDynamicFloat(
      this.dataTracker,
      'quantity',
      () => {
        return this.quantity;
      },
      (_value: EventValue) => {
        console.log('Quantity is readonly');
      },
      () => false
    );

    addDynamicFloat(
      this.dataTracker,
      'percentMarginOrMarkup',
      () => {
        return this.quoteItemPrice.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup;
      },
      (value: EventValue) => {
        if (value === this.quotePrice.percentMarginOrMarkup) this.quoteItemPrice.percentMarginOrMarkup = null;
        else this.quoteItemPrice.percentMarginOrMarkup = value as number;
      },
      () => false
    );

    addDynamicMoney(
      this.dataTracker,
      'subTotal',
      () => this.quoteItemPrice.calculatedNetSellingPrice,
      (_value: EventValue) => {
        console.log('suppilerCost readonly');
      },
      () => true
    );

    this.dataTracker.addObjectBinding(
      () => this.quoteItemPrice,
      'priceAdjustment',
      'priceAdjustment',
      FieldType.money,
      false
    );
    this.dataTracker.addObjectBinding(
      () => this.quoteItemPrice,
      'calculatedGrossSellingPrice',
      'calculatedGrossSellingPrice',
      FieldType.money,
      false
    );
  }

  protected get modalSize() {
    return 'modal-md';
  }

  async canClose(): Promise<boolean> {
    if (isAutoSaving()) {
      if (!(await this.isValid())) return false;
      this.save();
    }
    return true;
  }

  getValidationErrors(): string[] {
    const result: string[] = [];
    if (!inRange(this.quoteItemPrice.percentMarginOrMarkup, 0, 99))
      result.push(tlang`Please set a valid ${this.quotePrice.calculation} from 0 to 99`);
    return result;
  }

  getMarginLabel(): string {
    return tlang`${QuotePriceCalculationToStr(this.quotePrice.calculation)} (default ${this.quotePrice.percentMarginOrMarkup.toString()}%)`;
  }

  protected eventChangeGrossSellingPrice = () => {
    const p = this.quoteItemPrice;
    const sellingPrice = (this.dataTracker.getEditorValue('calculatedGrossSellingPrice') as number) ?? 0;
    if (p.calculatedGrossSellingPrice === sellingPrice) return;

    const priceAdjustment = sellingPrice - p.calculatedNetSellingPrice;
    p.priceAdjustment = money(priceAdjustment, 2);
    this.requestUpdate();
  };
  protected eventChangeMarginOrMarkup = () => {
    const p = this.quoteItemPrice;
    //anything over 100 will be treated as 100.
    //100 will result in an infinity error, which will throw a validation on trying to close.
    let marginPerc: number | null =
      (this.dataTracker.getEditorValue('percentMarginOrMarkup') as number) ?? this.quotePrice.percentMarginOrMarkup;
    if (marginPerc === this.quotePrice.percentMarginOrMarkup) marginPerc = null;

    if (p.percentMarginOrMarkup === marginPerc) return;

    p.percentMarginOrMarkup = marginPerc;
    //we need to do this because lit doesnt recognize the change when we want empty string to default
    this.dataTracker.setEditorValue('percentMarginOrMarkup', marginPerc);

    p.calculatedNetSellingPrice = money(
      this.quotePrice.calculation == QuotePriceCalculation.Margin
        ? PricingStrategy.Margin.getPriceWithAdjustment(
            p.quantityCost,
            Math.min(marginPerc ?? this.quotePrice.percentMarginOrMarkup, 100),
            undefined
          )
        : PricingStrategy.Markup.getPriceWithAdjustment(
            p.quantityCost,
            Math.min(marginPerc ?? this.quotePrice.percentMarginOrMarkup, 100),
            undefined
          ),
      2
    );
    p.calculatedGrossSellingPrice = money(p.calculatedNetSellingPrice + p.priceAdjustment, 2);
    this.requestUpdate();
  };
  protected eventKeyupMarginOrMarkup = (e: CustomEvent<KeyboardEvent>) => {
    if (e.detail.code === 'Enter') {
      this.eventChangeMarginOrMarkup();
    }
  };
  protected eventKeyupGrossSellingPrice = (e: CustomEvent<KeyboardEvent>) => {
    if (e.detail.code === 'Enter') {
      this.eventChangeGrossSellingPrice();
    }
  };
  protected eventKeyupPriceAdjustment = (e: CustomEvent<KeyboardEvent>) => {
    if (e.detail.code === 'Enter') {
      this.eventChangePriceAdjustment();
    }
  };
  protected eventChangePriceAdjustment = () => {
    const p = this.quoteItemPrice;
    const value = (this.dataTracker.getEditorValue('priceAdjustment') as number) ?? 0;
    if (p.priceAdjustment === value) return;

    console.log('PriceAdjustment Changing');
    p.priceAdjustment = value;
    p.calculatedNetSellingPrice = money(
      this.quotePrice.calculation === QuotePriceCalculation.Margin
        ? PricingStrategy.Margin.getPriceWithAdjustment(
            p.quantityCost,
            p.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup,
            undefined
          )
        : PricingStrategy.Markup.getPriceWithAdjustment(
            p.quantityCost,
            p.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup,
            undefined
          ),
      2
    );

    p.calculatedGrossSellingPrice = money(p.calculatedNetSellingPrice + p.priceAdjustment, 2);
    this.requestUpdate();
  };

  protected async isValid(): Promise<boolean> {
    this.prepareForSave();
    return await checkValidations(this.getValidationErrors());
  }

  protected getTitle(): Snippet {
    return html`${tlang`Line Item Price`}`;
  }

  protected prepareForSave() {
    if (this.forceReadonly) return;
    this.dataTracker.applyChangeToValue();
  }

  protected save() {
    this.ok = !compare(this.originalQuoteItemPrice, this.quoteItemPrice);
    Object.assign(this.originalQuoteItemPrice, this.quoteItemPrice);
  }

  protected footerTemplate(): TemplateResult {
    const saveEvent = async () => {
      if (await this.isValid()) {
        this.save();
        await this.hideModal();
      }
    };
    const closeEvent = () => this.hideModal();

    return this.forceReadonly
      ? html` <webmodule-button size="small" variant="default" @click="${closeEvent}">Close</webmodule-button>`
      : html`
          <webmodule-button size="small" variant="default" @click=${closeEvent}>Close</webmodule-button>
          <webmodule-button size="small" variant="primary" @click=${saveEvent}>Save</webmodule-button>
        `;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
    return html`
      <form class="line-item-price-adjustment form-one-col">
        <div class="row">
          <h2>${tlang`%%franchisee%%`}</h2>
          <div class="form-column">
            ${forms.intReadonly('quantity', tlang`Quantity`)}
            ${forms.float('percentMarginOrMarkup', this.getMarginLabel(), {
              events: {
                blur: this.eventChangeMarginOrMarkup,
                keyup: this.eventKeyupMarginOrMarkup
              }
            })}
            ${forms.moneyReadonly('subTotal', tlang`Sub Total`)}
            ${forms.money('priceAdjustment', tlang`Price Adjustment`, {
              events: {
                blur: this.eventChangePriceAdjustment,
                keyup: this.eventKeyupPriceAdjustment
              }
            })}
            ${forms.money('calculatedGrossSellingPrice', tlang`Sell Price`, {
              events: {
                blur: this.eventChangeGrossSellingPrice,
                keyup: this.eventKeyupGrossSellingPrice
              }
            })}
          </div>
        </div>
      </form>
    `;
  }
}
