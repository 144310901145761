import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../../webmodule-common/other/ui/modal-confirmation';
import { cache } from '../../cache-impl/cache-registry';
import {
  createStagedEvent,
  defaultTestInterval,
  EventCancellation
} from '../../../../webmodule-common/other/general/staged-event';
import { customElement } from 'lit/decorators.js';
import { DataTableWrapper } from '../../../../webmodule-common/other/ui/datatable-view';
import {
  DocumentCategory,
  DocumentState,
  ResourceType,
  ViewProjectDocument
} from '../../../api/dealer-api-interface-project';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { EventResource } from '../../../projects/views/project-resource-view';
import { EventTemplate, Snippet } from '../../../../webmodule-common/other/ui/events';
import { fireQuickSuccessToast } from '../../../../webmodule-common/other/toast-away';
import { getEnum } from '../../../../webmodule-common/other/FormInputView';
import { getPurchaseOrderNumberFormatted } from '../../../purchase-orders/data/purchase-order-helper-functions';
import { getQuoteNumberFormatted } from '../../../quotes/data/quote-helper-functions';
import { html } from 'lit';
import { ItemReference } from '../../../../webmodule-common/cache/definitions/cache-item-reference';
import { notificationSignal } from '../../../../webmodule-common/other/ui/icons/icon-notification-signal';
import { ProjectContainerManager } from '../../../projects/data/project-container';
import { ProjectDocumentView, ProjectDocumentViewOptions } from '../../../projects/views/project-document-view';
import { ProjectResourceLink } from '../../cache-impl/resource-link';
import { PurchaseOrder } from '../../../api/dealer-api-interface-franchisee';
import { QuoteCacheData } from '../../cache-impl/cache-data';
import { refreshButton } from '../../../../webmodule-common/other/ui/command-action';
import { RequestPage, ResultPaginated } from '../../../../webmodule-common/other/ui/RequestPage';
import { resolveURL } from '../../../../webmodule-common/other/ui/resource-resolver';
import { resourcePurchaseOrder } from '../../../purchase-orders/ui/launcher';
import { resourceQuote } from '../../../quotes/ui/launcher';
import { SSEDocumentTracker } from '../../../projects/data/sse-documenttracker';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { ViewQuoteSummary } from '../../../api/dealer-api-interface-quote';
import { WMEventSource } from '../../../api/event-source';

interface FranchiseeProjectDocumentTableOptions {
  projectManager: ProjectContainerManager;
  deleteDocumentEvent: EventResource;
}
@customElement('wm-franchiseeprojectdocumenttable')
export class FranchiseeProjectDocumentTable extends DataTableWrapper<ViewProjectDocument> {
  projectManager: ProjectContainerManager;
  projectResourceLinkCache = cache().projectResourceLink;
  quoteCache = cache().quote;
  purchaseOrderCache = cache().purchaseOrder;
  userCache = cache().userProfile;
  deleteDocumentEvent: EventResource;

  constructor(options: FranchiseeProjectDocumentTableOptions) {
    super();
    this.projectManager = options.projectManager;
    this.deleteDocumentEvent = options.deleteDocumentEvent;
  }

  public verticalHeight(): string | undefined {
    return undefined;
  }
  public forceRefresh(): void {
    super.forceRefresh();
    this.projectManager.clearContainer();
  }
  public async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<ViewProjectDocument>> {
    await this.projectManager.needsProject();
    const items = this.projectManager.container.documents;

    if (!items)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    const resourceKeys = items.map(x => x.documentTracker.resourceId ?? emptyGuid) ?? [];

    await this.projectResourceLinkCache
      .getMany(resourceKeys)
      .then(async (data: ItemReference<ProjectResourceLink>[] | null) => {
        if (!data) return;

        const qKeys = data?.filter(x => x.data.resourceType == ResourceType.Quote).map(x => x.data.quoteId);
        const pKeys = data
          ?.filter(x => x.data.resourceType == ResourceType.PurchaseOrder)
          .map(x => x.data.purchaseOrderId);

        const qCachePreFetch = this.quoteCache
          .getMany(qKeys)
          .then(async (data: ItemReference<QuoteCacheData>[] | null) => {
            const uKeys = data?.map(x => x.data.quoteSummary.creationUserId);

            return this.userCache.preFetch(uKeys ?? []);
          });

        // eslint-disable-next-line consistent-return
        return await Promise.all([qCachePreFetch, this.purchaseOrderCache.preFetch(pKeys)]);
      });

    const getResourceNumber = (a: ViewProjectDocument) => {
      let number = 0;
      switch (a.documentTracker.resourceType) {
        case ResourceType.Quote:
          number = this.quoteCache.getLocal(a.documentTracker.resourceId)?.data.quoteSummary.quoteNumber ?? 0;
          break;
        case ResourceType.PurchaseOrder:
          number =
            this.purchaseOrderCache.getLocal(a.documentTracker.resourceId)?.data.purchaseOrder.purchaseOrderNumber ?? 0;
          break;
        default:
          break;
      }

      return number;
    };

    const documentCompare = (a: ViewProjectDocument, b: ViewProjectDocument) => {
      const aRType = a.documentTracker.resourceType;
      const bRType = b.documentTracker.resourceType;
      const aRNum = getResourceNumber(a);
      const bRNum = getResourceNumber(b);

      const typeComparison = (aRType == bRType ? 0 : aRType > bRType ? 1 : -1) * 100;
      const numberComparison = (aRNum == bRNum ? 0 : aRNum > bRNum ? 1 : -1) * 10;
      const dateComparison = b.documentTracker.dateCreated.localeCompare(a.documentTracker.dateCreated);

      return typeComparison + numberComparison + dateComparison;
    };

    return {
      count: items.length,
      pageCount: 1,
      pageIndex: 0,
      pageSize: items.length,
      results: items?.sort((a, b) => documentCompare(a, b))
    };
  }

  public getRowGroup(): any {
    return {
      startRender: (rows, group: string, level: number) => {
        if (level == 0) {
          const enumValue: ResourceType = getEnum(ResourceType, group);

          return enumValue == ResourceType.Quote
            ? tlang`!!quote!!`
            : enumValue == ResourceType.PurchaseOrder
              ? tlang`!!purchase-order!!`
              : 'Miscellaneous';
        }

        // eslint-disable-next-line consistent-return
        if (rows.length == 0) return;

        return this.getResourceLink(group);
      },
      dataSrc: [
        'documentTracker.resourceType',
        function (row: ViewProjectDocument) {
          return `${row.documentTracker.resourceType};${row.documentTracker.resourceId}`;
        }
      ]
    };
  }

  public getColumns(): any[] {
    return [
      {
        title: tlang`Creation Date`,
        width: '50px',
        data: 'documentTracker.dateCreated',
        className: 'document-creation-date',
        render: (_value: string, _type: never, row: ViewProjectDocument) => {
          const dt = new Date(row.documentTracker.dateCreated);
          return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
        }
      },
      {
        title: tlang`File Name`,
        width: '50px',
        data: 'documentTracker.description',
        className: 'document-description',
        render: (_value: string, _type: never, row: ViewProjectDocument) => {
          return row.documentTracker.state == DocumentState.Available
            ? this.getPublicLink(row.documentTracker.description, row.publicFileURL)
            : _value;
        }
      },
      {
        title: tlang`Status`,
        width: '50px',
        data: 'documentTracker.state',
        className: 'document-state',
        render: (value: string, _type: never, _row: ViewProjectDocument) => {
          return `<span class="document-state-${DocumentState[value].toLowerCase().replace(' ', '-')}">
                        ${DocumentState[value]}
                    </span>`;
        }
      },
      {
        title: '...',
        width: '32px',
        data: 'documentTracker.id',
        orderable: false,
        className: 'item-menu',
        render: (_value: number, _type: never, row: ViewProjectDocument) => {
          return this.ellipsisMenu(row);
        }
      }
    ];
  }

  ellipsisMenu(row: ViewProjectDocument): string {
    return row.documentTracker.category == DocumentCategory.Temporary &&
      (row.documentTracker.state == DocumentState.Available ||
        row.documentTracker.state == DocumentState.Failed ||
        row.documentTracker.state == DocumentState.Deleted)
      ? `
            <a class="documents-delete" href="#">
                <img class="icon action-delete" src="/assets/icons/delete.svg" alt="Trash can">
            </a>`
      : '';
  }

  public bindClickEvents($dataTable: any) {
    $dataTable.on(
      'click',
      '.action-delete',
      this.eventHandler(async (data: ViewProjectDocument) => {
        await this.deleteDocumentEvent(data.documentTracker.id);
      })
    );
  }

  protected getPublicLink(description: string, link: string) {
    return `<a href="${link}">${this.htmlEncode(description)}</a>`;
  }

  protected ordering(): boolean {
    return false;
  }

  private getResourceLink(resourceData: string) {
    const getAuthor = (id: string) => this.userCache.getLocal(id)?.data.friendlyName;

    //The resource data consist of resourceType;ResourceId
    const data = resourceData.split(';');
    const enumValue: ResourceType = getEnum(ResourceType, data[0]);
    const resourceId = data[1];

    let rClass = '';
    let rNumber = '';
    let resourceType = '';

    if (enumValue == ResourceType.Quote) {
      const quoteSummary = this.quoteCache.getLocal(resourceId)?.data.quoteSummary;
      resourceType = resourceQuote;
      const author = quoteSummary ? getAuthor(quoteSummary.assignedToUserId) : undefined;
      rNumber = quoteSummary
        ? author
          ? tlang`${getQuoteNumberFormatted(
              quoteSummary as ViewQuoteSummary
            )} - ${quoteSummary.title} (%%author%%: ${author})`
          : getQuoteNumberFormatted(quoteSummary as ViewQuoteSummary)
        : '';
      rClass = 'quote';
    } else if (enumValue == ResourceType.PurchaseOrder) {
      const poSummary = this.purchaseOrderCache.getLocal(resourceId)?.data.purchaseOrder;
      resourceType = resourcePurchaseOrder;
      const poAuthor = poSummary ? getAuthor(poSummary.creationUserId) : undefined;
      rNumber = poSummary
        ? poAuthor
          ? tlang`${getPurchaseOrderNumberFormatted(
              poSummary as PurchaseOrder
            )} - ${poSummary.title} (%%author%%: ${poAuthor})`
          : getPurchaseOrderNumberFormatted(poSummary as PurchaseOrder)
        : '';

      rClass = 'purchase-order';
    } else {
      alert('Missing resource link mapping');
      return `${enumValue} - ${resourceId}`;
    }

    return `<a class="documents-${rClass}-link" href="${resolveURL(
      resourceType,
      resourceId
    )}" data-resource-id="${resourceId}">${rNumber}</a>`;
  }
}
@customElement('wm-franchiseeprojectdocumentview')
export class FranchiseeProjectDocumentView extends ProjectDocumentView {
  private documentTable: FranchiseeProjectDocumentTable;

  constructor(options: ProjectDocumentViewOptions) {
    super(options);

    this.documentTable = this.getDocumentTableFactory();
  }

  public override async invalidate(): Promise<void> {
    await super.invalidate();
    this.documentTable.invalidate();
  }

  public async refreshData(): Promise<void> {
    await this.documentTable.refreshData();
  }

  public async refreshButtonClick() {
    this.documentTable.forceRefresh();
    await this.refreshData();
  }
  public buttonMenu(): Snippet {
    return html`${refreshButton(async () => {
      await this.refreshButtonClick();
    })}`;
  }
  private _eventCancel: EventCancellation = new EventCancellation();
  private _gatedRefreshEvent = createStagedEvent({
    eventFinally: () => notificationSignal(false),

    event: () => {
      this.dispatchCustom('!related-changed', false);
      this.refreshButtonClick();
    },
    cancelToken: this._eventCancel,
    testInterval: defaultTestInterval,
    threshold: 5000,
    eventTriggered: () => {
      this.dispatchCustom('!related-changed', true);
      notificationSignal(true);
    }
  });
  private _eventDocument = (data: unknown, _eventName: string) => {
    const documents = (data as SSEDocumentTracker).links;
    if (documents.some(x => x.documentTracker.projectId === this.projectManager.projectId)) this._gatedRefreshEvent();
  };
  async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    WMEventSource.getInstance().addEventListener(
      [WMEventSource.projectDocument, WMEventSource.projectDocumentDel],
      this._eventDocument
    );
  }
  async dispose(): Promise<void> {
    WMEventSource.getInstance().removeEventListener(
      [WMEventSource.projectDocument, WMEventSource.projectDocumentDel],
      this._eventDocument
    );
    await super.dispose();
  }
  protected getDocumentTableFactory() {
    return new FranchiseeProjectDocumentTable({
      projectManager: this.projectManager,
      deleteDocumentEvent: async (id: string) => await this.deleteDocument(id)
    });
  }

  protected template(): EventTemplate {
    return html`
      <h2 class="section-header">${tlang`Generated !!document!!`}</h2>
      <div class="row documents-dtrg-table">${this.documentTable}</div>
    `;
  }

  private async deleteDocument(id: string): Promise<boolean> {
    if (
      await AskConfirmation(
        tlang`Are you sure you want to delete the %%document%%?`,
        confirmationButtons[ConfirmationButtonType.yesNo],
        undefined,
        tlang`Delete %%document%%?`
      )
    ) {
      const success = await this.projectManager.removeDocument(id);

      if (success) {
        fireQuickSuccessToast(tlang`%%document%% deleted`);
        await this.refreshData();
      }

      return success;
    }
    return false;
  }
}
