import { DevelopmentError } from '../../webmodule-common/other/development-error';
import { information } from '../../webmodule-common/other/ui/modal-option';
import { noParalellExecutionAsync } from '../../webmodule-common/other/common/helpers/callbacks';
import { QuoteSupplier, V6OfflineCause } from '../quotes/quote-service';
import { tlang } from '../../webmodule-common/other/language/lang';

interface GSNCache {
  supplierId: string;
  gsn: GlobalSupplierNotification;
}
export class GlobalSupplierNotification {
  private static _instances: GSNCache[] = [];
  private _events: ((gsn: GlobalSupplierNotification) => void)[] = [];
  private _quoteSupplier?: QuoteSupplier;
  supplierId: any;
  constructor(supplierId) {
    this.supplierId = supplierId;
  }
  public static getDefaultInstance(): GlobalSupplierNotification | undefined {
    if (this._instances.length > 0) return this._instances[0].gsn;
    return undefined;
  }

  public static getInstance(supplierId?: string): GlobalSupplierNotification {
    if (!supplierId) {
      if (this._instances.length > 0) return this._instances[0].gsn;
      throw new DevelopmentError('GlobalSupplierNotification missing default supplier');
    }
    let instance = this._instances.find(x => x.supplierId === supplierId);
    if (!instance) {
      instance = { supplierId: supplierId, gsn: new GlobalSupplierNotification(supplierId) };
      this._instances.push(instance);
    }
    return instance.gsn;
  }
  private _date?: Date;
  private _online?: boolean;
  public get online(): boolean {
    return this._online ?? false;
  }

  private _offlineCause?: V6OfflineCause | undefined;
  public get offlineCause(): V6OfflineCause | undefined {
    return this._offlineCause;
  }
  public extraText: string = '';
  public get offlineMessage(): string {
    const supplierName = this._quoteSupplier?.description ?? tlang`%%supplier%%`;
    switch (this._offlineCause) {
      case V6OfflineCause.Connection:
        return tlang`${supplierName} is offline. Cannot connect since ${this._date?.toLocaleTimeString()}. ${this.extraText}`;
      case V6OfflineCause.Upgrading:
        return tlang`${supplierName} is offline for an upgrade since ${this._date?.toLocaleTimeString()}. Expected to resume within 1 hr. ${this.extraText}`;
      case V6OfflineCause.VersionMismatch:
        return tlang`${supplierName} is offline because of a system mismatch`;
      default:
        return tlang`${supplierName} is offline for an unknown reason since ${this._date?.toLocaleTimeString()}. ${this.extraText}`;
    }
  }
  public addEventListenter(event: (gsn: GlobalSupplierNotification) => void) {
    this._events = [...this._events.filter(x => x !== event), event];
  }
  public remEventListenter(event: (gsn: GlobalSupplierNotification) => void) {
    this._events = this._events.filter(x => x !== event);
  }

  public async makeOffline(offlineCause: V6OfflineCause) {
    this._offlineCause = offlineCause;
    const wasOnline = this._online;
    this._online = false;
    if (wasOnline) await this.showOfflineMessage();
    this._events.forEach(x => x(this));
  }
  public static displaySupplierOffLineMsg = noParalellExecutionAsync(displaySupplierOffLineMsg);
  public static displaySupplierUpgradingMsg = noParalellExecutionAsync(displaySupplierOffUpgrade);

  public static async showOfflineMessage(offlineCause?: V6OfflineCause) {
    if (offlineCause === V6OfflineCause.Upgrading) await GlobalSupplierNotification.displaySupplierUpgradingMsg();
    else await GlobalSupplierNotification.displaySupplierOffLineMsg();
  }
  async showOfflineMessage() {
    if (this._offlineCause === V6OfflineCause.Upgrading) await GlobalSupplierNotification.displaySupplierUpgradingMsg();
    else await GlobalSupplierNotification.displaySupplierOffLineMsg();
  }

  public setState(quotesupplierInfo: QuoteSupplier) {
    if (
      this._online !== quotesupplierInfo.online ||
      (!this._online && this._offlineCause !== quotesupplierInfo.offlineCause)
    ) {
      this._offlineCause = quotesupplierInfo.offlineCause;
      this._online = quotesupplierInfo.online;
      this._quoteSupplier = quotesupplierInfo;
      this._date = new Date();
      console.log('GlobalSupplierNotification: state changed');
      this._events.forEach(x => x(this));
    } else {
      console.log('GlobalSupplierNotification: state ignored');
    }
  }
}

async function displaySupplierOffUpgrade() {
  await information(
    tlang`${'ref:supplier-offline-upgrading'}The %%supplier%% System is current OFFLINE performing an Upgrade. This should normally take no
        more than one hour. Please contact support ASAP to resolve this issue if it does not come back online after one hour.`,
    tlang`${'ref:supplier-offline-title'}%%supplier%% OFFLINE`
  );
}

async function displaySupplierOffLineMsg() {
  await information(
    tlang`${'ref:supplier-offline-msg'}The activity you are trying to do is being blocked because the %%supplier%% system is OFFLINE.
                     Please contact support ASAP to resolve this issue. We recommend closing down what you are doing until this is resolved`,
    tlang`${'ref:supplier-offline-title'}%%supplier%% OFFLINE`
  );
}

export async function displayFrameConfigLoadErrorMsg() {
  await information(
    tlang`${'ref:supplier-frameconfig-offline-msg'}The activity you are trying to do is being blocked because the %%supplier%% %%frame%% config could not be loaded.
                     Please contact support ASAP to resolve this issue. We recommend closing down what you are doing until this is resolved`,
    tlang`${'ref:supplier-offline-title'}%%supplier%% OFFLINE`
  );
}

let _displaySupplierTACNotApprovedMsg = false;
export async function displaySupplierTACNotApprovedMsg(supplier: QuoteSupplier, force?: boolean) {
  if (!force && _displaySupplierTACNotApprovedMsg) return;
  _displaySupplierTACNotApprovedMsg = true;
  await information(tlang`${'ref:tac-approval-not-set-msg'}The %%supplier%% (${supplier.description}) Terms and Conditions 
    have not been accepted by the %%franchisee%%. They will still need to accept the terms and conditions before using this feature`);
  // eslint-disable-next-line consistent-return
  return false;
}
