import { cache } from '../../dealer-franchisee/cache-impl/cache-registry';
import { ClientApi } from '../../api/client-api';
import { ClientPicker } from './client-picker';
import { Contact } from '../../api/dealer-api-interface-client';
import { ContactContainerManager } from '../data/contact-container-manager';
import { customElement, state } from 'lit/decorators.js';
import { DataBinding, getInternalId } from '../../../webmodule-common/other/ui/databinding/databinding';
import {
  DataEntryPageControlView,
  PageControlChildTab
} from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import {
  FormInputAssistant,
  PickerResult
} from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getApiFactory } from '../../api/api-injector';
import { html } from 'lit';
import { isEmptyOrSpace, isValidEmail, validId } from '../../../webmodule-common/other/ui/string-helper-functions';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { WebmoduleChangeEvent, WebmoduleToggle } from '../../../webmodule-common/components/src/webmodule-components';
import { emptyGuid } from '../../../webmodule-common/other/api/guid';

export interface ContactDetailViewOptions {
  contactManager: ContactContainerManager;
}

@customElement('wm-contactdetailview')
export class ContactDetailView extends PageControlChildTab {
  contactManager: ContactContainerManager;
  clientApi: ClientApi = getApiFactory().client();
  internalId: string;
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  @state()
  clientPickerDisplay = '';

  constructor(owner: DataEntryPageControlView, options: ContactDetailViewOptions) {
    super(owner);

    this.internalId = getInternalId();

    this.contactManager = options.contactManager;

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `contact-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    //Think we can refactor this
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.contact),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('clientId');
    addField('name');
    addField('title');
    addField('email');
    addField('mobile');
  }

  protected get contact(): Contact {
    return this.contactManager.contact;
  }

  protected get isPrimary(): boolean {
    return this.contactManager.container.isPrimary;
  }

  async onEnter(): Promise<void> {
    await this.loadOrRefresh();
  }

  public allowDeletePage(): boolean {
    return false;
  }

  public async loadOrRefresh(): Promise<void> {
    await this.contactManager.needsContact();
    this.clientPickerDisplay = (await cache().client.get(this.contact.clientId))?.displayValue ?? '';
  }

  public async prepareForSave() {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }

  public getValidationErrors() {
    const errors: string[] = [];

    const name = this.dataBinding.getValue('name');
    const email = this.dataBinding.getValue('email');
    const clientId = this.dataBinding.getValue('clientId');

    if (isEmptyOrSpace(clientId) || clientId == emptyGuid) errors.push(tlang`Please select a %%client%%`);

    if (isEmptyOrSpace(name)) errors.push(tlang`Please provide a %%contact%% Name`);

    if (isEmptyOrSpace(email)) errors.push(tlang`Please provide an Email`);
    else if (!isValidEmail(email)) errors.push(tlang`Please provide a valid Email`);

    return errors;
  }

  protected getCaption(): Snippet {
    return tlang`%%contact%% Info`;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    const newPrimaryContactMsg = tlang`This %%contact%% will be the new primary %%contact%% for the %%client%%`;
    const removePrimaryContactMsg = tlang`You are about to remove this %%contact%% as the primary %%contact%% for the %%client%%`;
    const isPrimaryWarning =
      this.isPrimary == this.contactManager.backup.isPrimary
        ? html``
        : this.isPrimary
          ? html`<div class="text-warning primary-toggle-message">${newPrimaryContactMsg}</div>`
          : html`<div class="text-danger primary-toggle-message">${removePrimaryContactMsg}</div>`;

    const clientIdPicker = validId(this.contact.clientId)
      ? forms.pickerReadonly('clientId', this.getClientPickerDisplay(), tlang`%%client%%`)
      : forms.pickerRequired(
          'clientId',
          this.getClientPickerDisplay(),
          async () => await this.selectClient(),
          tlang`%%client%%`
        );
    return html`
      <form class="frm-client-details form-one-col">
        <div class="row">
          <div>
            ${clientIdPicker} ${forms.textRequired('name', tlang`Name`, 100)} ${forms.text('title', tlang`Role`, 50)}
            ${forms.textRequired('email', tlang`Email`, 100)} ${forms.text('mobile', tlang`Mobile`, 30)}

            <div class="primary-toggle-wrapper">
            <webmodule-toggle
              id=${`contact-is-primary-${this.internalId}`}
              ?checked=${this.isPrimary}
              ?disabled=${this.contactManager.backup.isPrimary}
              @webmodule-change=${(e: WebmoduleChangeEvent) => this.changeIsPrimary(e)}
              size="small"
            >
              ${tlang`Primary %%contact%%`}
            </webmodule-toggle>

            ${isPrimaryWarning}
            </div>
          </div>
        </div>
      </form>
    `;
  }

  private getClientPickerDisplay() {
    return this.clientPickerDisplay;
  }

  private changeIsPrimary(e: WebmoduleChangeEvent) {
    const target = e.target as WebmoduleToggle | null;
    if (!target) return;

    this.contactManager.container.isPrimary = target.checked;
    this.requestUpdate(); //no wait
  }

  private async selectClient(): Promise<PickerResult | undefined> {
    const selectedClient = await ClientPicker();
    return selectedClient ? { id: selectedClient.id, value: selectedClient.name } : undefined;
  }
}
