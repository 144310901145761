import {
  BranchQuote,
  BranchQuoteSupport,
  BranchQuoteSupportItemType
} from '../../../api/dealer-api-interface-franchisee';
import { cache } from '../../cache-impl/cache-registry';
import { constructAsync } from '../../../../webmodule-common/other/async-constructor';
import { CreateTicketHandler, FranchiseeQuoteSupportView } from './franchisee-quote-support-view';
import { currentUserClaims } from '../../../../webmodule-common/other/currentuser-claims';
import { customElement, state } from 'lit/decorators.js';
import { DataEntryOwner } from '../../../../webmodule-common/other/ui/DataEntryOwner';
import { DealerReport } from '../../../api/dealer-api-interface-clientagent';
import { DevelopmentError } from '../../../../webmodule-common/other/development-error';
import { disposeOf } from '../../../../webmodule-common/other/dispose';
import { DocumentSubType, ResourceType } from '../../../api/dealer-api-interface-project';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { EngineeredToOrderLineItemView } from './franchisee-engineered-to-order-view';
import { fireQuickErrorToast } from '../../../../webmodule-common/other/toast-away';
import { flagInSet, isEmptyOrSpace, validId } from '../../../../webmodule-common/other/ui/string-helper-functions';
import { FranchiseeQuoteContainer } from '../data/franchisee-quote-container';
import { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { FranchiseeQuoteDetailView } from './franchisee-quote-detail-view';
import { FranchiseeQuoteItemFrameViewForV6 } from './v6/franchisee-v6-quote-item-frame-view';
import { FranchiseeQuoteItemsView } from './franchisee-quote-items-view';
import { FranchiseeQuoteSupportTicketView } from './franchisee-support-ticket-editor-view';
import { FranchiseeV6QuoteSupplierDefaultsView } from './v6/franchisee-v6-quote-defaults-view';
import { FranchiseeV6QuoteSupplierGlassView } from './v6/franchisee-v6-supplier-quote-glass-view';
import { getApiFactory } from '../../../api/api-injector';
import { getPermanentProjectDocumentURL } from '../../cache-impl/project-permanent-document-lookup';
import { getQuoteStateEngine } from '../data/quote-state-engine';
import { getQuoteSuppliers, isMultiSupplier } from '../../../quotes/quote-service';
import { getQuoteTitle, isFrame, isSpecialItem } from '../../../quotes/data/quote-helper-functions';
import { goURL, resolveURL } from '../../../../webmodule-common/other/ui/resource-resolver';
import { html, TemplateResult } from 'lit';
import { information } from '../../../../webmodule-common/other/ui/modal-option';
import { InformationDispatcher } from '../../../../webmodule-common/other/ui/information-dispatcher';
import { IQuoteValidator } from '../../../quotes/data/quote-container';
import { isSupplierUsingSSI } from '../../../quotes/data/v6/helper-functions';
import { isValidV6ConfigVersion, v6Config, v6SupportsVersion, v6VersionMap } from '../../../v6config/v6config';
import { IUserQuoteStateEngine } from '../../../quotes/data/quote-state-engine';
import { MenuIconEventList, MenuIconOption, PageManager } from '../../../../webmodule-common/other/ui/page-control';
import { NullPromise, Snippet } from '../../../../webmodule-common/interop/webmodule-interop';
import { OpenTicketHandler } from '../../conversation/wm-branchquote-support';
import { Quote, QuoteItem, QuoteState } from '../../../api/dealer-api-interface-quote';
import {
  QuoteDataEntryView,
  QuoteView,
  QuoteViewChildFactory,
  QuoteViewChildFactoryImpl,
  QuoteViewOptions
} from '../../../quotes/views/quote-view';
import { QuoteDetailView, QuoteDetailViewOptions } from '../../../quotes/views/quote-detail-view';
import { quoteItemAction } from '../../../quotes/data/events';
import { QuoteItemContainer } from '../../../quotes/data/quote-item-container';
import { quoteItemContentType } from '../../../quotes/data/default-quote-item-content-type';
import { QuoteItemsView, QuoteItemsViewOptions } from '../../../quotes/views/quote-items-view';
import { QuoteItemView, QuoteItemViewOptions } from '../../../quotes/views/quote-item-view';
import {
  QuoteSupplierDefaultsView,
  QuoteSupplierDefaultsViewOptions
} from '../../../quotes/views/quote-supplier-defaults.view';
import { QuoteSupplierGlassView, QuoteSupplierGlassViewOptions } from '../../../quotes/views/quote-supplier-glass-view';
import { resourceProject } from '../../../projects/ui/launcher';
import { resourcePurchaseOrder } from '../../../purchase-orders/ui/launcher';
import { SaveWorkflowModal } from '../../../../webmodule-common/other/save-workflow';
import { supplierQuoteItemContentType } from '../../../quotes/data/supplier-quote-item-content-type';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { V6OutOfDateQuoteItemHandler } from '../data/v6/out-of-date-quote-item-handler';
import { V6OutOfDateQuoteItemHandlerSafeMode } from '../data/v6/out-of-date-quote-item-handler-safe-mode';
import { V6SupplierQuoteBase } from '../../../quotes/data/v6/v6-supplier-quote';
import { validateOutOfDateQuoteItems } from '../../../quotes/views/quote-item-validation-modal';

export class FranchiseeQuoteViewChildFactory extends QuoteViewChildFactoryImpl {
  protected get view(): FranchiseeQuoteDEView {
    return this.parent as FranchiseeQuoteDEView;
  }

  getDetailView(options: QuoteDetailViewOptions): QuoteDetailView {
    return new FranchiseeQuoteDetailView({
      quoteManager: options.quoteManager
    });
  }

  getItemsView(options: QuoteItemsViewOptions): QuoteItemsView {
    return new FranchiseeQuoteItemsView(options);
  }

  getSupplierDefaultsView(options: QuoteSupplierDefaultsViewOptions): QuoteSupplierDefaultsView {
    if (options.quoteManager.isV6) return new FranchiseeV6QuoteSupplierDefaultsView(options);
    //V7?
    return super.getSupplierDefaultsView(options);
  }

  getSupplierGlassView(options: QuoteSupplierGlassViewOptions): QuoteSupplierGlassView {
    if (options.quoteManager.isV6) return new FranchiseeV6QuoteSupplierGlassView(options);
    //V7?
    return super.getSupplierGlassView(options);
  }

  getSupplierQuoteItemView(
    contentType: number,
    options: QuoteItemViewOptions,
    supplierSelector?: unknown
  ): QuoteItemView {
    if (options.quoteManager.isV6) {
      if (contentType === supplierQuoteItemContentType.CID_FRAM)
        return new FranchiseeQuoteItemFrameViewForV6(options, supplierSelector);
    }
    return super.getSupplierQuoteItemView(contentType, options, supplierSelector);
  }
}

/**
 * abstract out logic that is driven by the supplier provider type, so that we can clean and seperate this logic out into
 * a factory injection
 */
export class QuoteSupplierProviderInterface {
  async mustValidateItems(qcm: FranchiseeQuoteContainerManager): Promise<boolean> {
    return await qcm.currentUserMustValidate();
  }

  /**
   * override this to add logic
   */
  async validateOutOfDateQuoteItems(qcm: FranchiseeQuoteContainerManager) {
    await validateOutOfDateQuoteItems(qcm, new V6OutOfDateQuoteItemHandler(qcm));
  }
  async reValidateOutOfDateQuoteItemsOnLockedQuote(qcm: FranchiseeQuoteContainerManager) {
    const handler = new V6OutOfDateQuoteItemHandlerSafeMode(qcm);
    await validateOutOfDateQuoteItems(qcm, handler);
  }
}

@customElement('wm-franchiseequotedeview')
export class FranchiseeQuoteDEView extends QuoteDataEntryView implements IQuoteValidator {
  prlCache = cache().projectResourceLink;
  projectCache = cache().project;
  purchaseOrderCache = cache().purchaseOrder;

  protected supportView: FranchiseeQuoteSupportView | null;

  protected providerInterface: QuoteSupplierProviderInterface = new QuoteSupplierProviderInterface();
  protected projectApi = getApiFactory().project();
  protected purchaseOrderApi = getApiFactory().purchaseOrder();
  @state()
  private _orderId: string = emptyGuid;
  @state()
  private _projectId: string = emptyGuid;
  @state()
  private _projectCacheDisplay: string | undefined;
  @state()
  private _orderCacheDisplay: string | undefined;
  @state()
  private _supplierTitle: TemplateResult = html``;
  @state()
  private _canCreateShopDrawingPreview: boolean = false;
  @state()
  private _permanentShopDrawingUrl: string | null = null;
  private _reportMenuItems: MenuIconEventList = [];

  public get quoteContainerManager(): FranchiseeQuoteContainerManager {
    return this._quoteContainerManager as FranchiseeQuoteContainerManager;
  }
  public set quoteContainerManager(value: FranchiseeQuoteContainerManager) {
    this._quoteContainerManager = value;
  }
  public get quote(): Quote {
    if (!this.quoteContainer.quote) throw new DevelopmentError('quote is null');
    return this.quoteContainer.quote;
  }
  public get quoteItems(): QuoteItem[] {
    if (!this.quoteContainer.items) throw new DevelopmentError('quote items is null');
    return this.quoteContainer.items;
  }
  protected get branchQuote(): BranchQuote | null {
    if (!(this.quoteContainerManager.container instanceof FranchiseeQuoteContainer))
      throw new DevelopmentError('FranchiseeQuoteView requires a container type FranchiseeQuoteContainer');
    return (this.quoteContainerManager.container as FranchiseeQuoteContainer).branchQuote;
  }
  protected async supportTicketAlreadyOpen(): Promise<boolean> {
    const alreadyOpen = this.supportTicketPagesOpenCount >= this.maxItemPageAllowed;
    if (alreadyOpen)
      await information(
        tlang`Please close and save the existing %%support-request%% editor before opening another one`
      );
    return alreadyOpen;
  }

  protected get supportTicketPagesOpenCount(): number {
    let count = 0;
    this._pageControl?.pages.forEach(page => {
      if (page.data instanceof FranchiseeQuoteSupportTicketView) {
        count++;
      }
    });
    return count;
  }

  protected openSupportTicketHandler = (event: OpenTicketHandler) => {
    switch (event.item.type) {
      case BranchQuoteSupportItemType.EngineeredToOrder: {
        const etoItem = this.quoteItems.find(item => {
          return event.links.some(x => x.quoteItemId === item.id);
        });
        if (etoItem) {
          event.handled = true;
          this.runQuoteItemAction(this.quoteContainerManager.quoteItemContainer(etoItem.id), quoteItemAction.edit);
        }
        return;
      }
      case BranchQuoteSupportItemType.QuoteAssistanceRequest:
      case BranchQuoteSupportItemType.QuoteReview:
      case BranchQuoteSupportItemType.Basic:
        event.handled = true;
        this.openSupportTicket(event.item);
    }
  };
  protected createSupportTicketHandler = (event: CreateTicketHandler) => {
    switch (event.type) {
      case BranchQuoteSupportItemType.QuoteAssistanceRequest:
      case BranchQuoteSupportItemType.Basic:
        event.handled = true;
        this.createSupportTicket(event.type);
    }
  };
  public async createSupportTicket(branchQuoteSupportType: BranchQuoteSupportItemType) {
    if (await this.supportTicketAlreadyOpen()) return;
    const editor = new FranchiseeQuoteSupportTicketView(this.quoteContainerManager, undefined, branchQuoteSupportType);
    const page = editor.createPageManager();
    this.pageControl.addPage(page);
  }

  protected customButtons(state: QuoteState): MenuIconOption[] {
    // eslint-disable-next-line no-bitwise
    if (flagInSet(state, QuoteState.Active | QuoteState.SupplierReviewPending)) {
      const addSupportTicket: MenuIconOption = {
        caption: () => tlang`Request Support`,
        event: async () => {
          await this.createSupportTicket(BranchQuoteSupportItemType.QuoteAssistanceRequest);
          return true;
        },
        disabled: this.isDataReadonly()
      };
      return [addSupportTicket];
    }
    return [];
  }
  public async openSupportTicket(branchQuoteSupport: BranchQuoteSupport) {
    if (await this.focusSupportTicketIfAvailable(branchQuoteSupport)) {
      return;
    }
    if (await this.supportTicketAlreadyOpen()) return;
    const editor = new FranchiseeQuoteSupportTicketView(this.quoteContainerManager, branchQuoteSupport);
    const page = editor.createPageManager();
    this.pageControl.addPage(page);
  }

  async focusSupportTicketIfAvailable(branchQuoteSupport: BranchQuoteSupport) {
    const foundPage = this.findIfSupportPageAvailable(branchQuoteSupport);
    if (foundPage) {
      await this.pageControl.setActivePage(foundPage);
      return true;
    }
    return false;
  }

  findIfSupportPageAvailable(branchQuoteSupport: BranchQuoteSupport): PageManager | undefined {
    return this.pageControl.pages.find(page => {
      if (page.data instanceof FranchiseeQuoteSupportTicketView) {
        if ((page.data as FranchiseeQuoteSupportTicketView).branchQuoteSupport?.id === branchQuoteSupport.id) {
          return true;
        }
      }
      return false;
    });
  }

  constructor(options: QuoteViewOptions, owner: DataEntryOwner) {
    super(options, owner);

    this.supportView = new FranchiseeQuoteSupportView({
      quoteManager: this.quoteContainerManager,
      openTicketHandler: this.openSupportTicketHandler,
      createTicketHandler: this.createSupportTicketHandler
    });

    this.quoteContainerManager.quoteValidator = this.detailView;
  }
  async dispose() {
    await super.dispose();

    await disposeOf(this.supportView);

    //override if needed
  }
  protected doAddPages(pages: PageManager[]) {
    if (this.quote.state != QuoteState.Draft) {
      pages.push(this.createSupportPage()); //override;
    }
  }

  protected async quoteItemViewFactory(
    quoteItemContainer: QuoteItemContainer | null,
    providerType: string,
    contentType: number,
    supplierSelector: unknown
  ): NullPromise<QuoteItemView> {
    if (providerType === '') {
      //these are the default non-provider line items
      switch (contentType) {
        case quoteItemContentType.specialItem:
          return await constructAsync(
            new EngineeredToOrderLineItemView(await this.getQuoteItemViewOptions(quoteItemContainer))
          );
        default:
          return await super.quoteItemViewFactory(quoteItemContainer, providerType, contentType, supplierSelector);
      }
    } else return await super.quoteItemViewFactory(quoteItemContainer, providerType, contentType, supplierSelector);
  }

  private createSupportPage(): PageManager {
    return {
      caption: () => tlang`%%supplier%% Support`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => true,
      hasDelete: () => false,
      onEnter: async () => {
        this.supportView?.refreshTable();
      },
      buttonMenu: () => this.supportView?.buttonMenu() ?? html``,
      content: () => {
        return this.supportView?.ui ?? document.createElement('div');
      },
      data: this.supportView,
      pageFragment: 'support'
    };
  }
  getQuoteStateEngine(): IUserQuoteStateEngine {
    return getQuoteStateEngine(this.quoteContainerManager);
  }

  addSupplierMenuItems(childEvents: MenuIconEventList) {
    super.addSupplierMenuItems(childEvents);
    const buttons = this.getStandardButtons();
    if (v6SupportsVersion(v6VersionMap.hasCheckSupplierQuote)) childEvents.push(buttons.createSupplierQuote);
  }

  addReportMenuItems(_childEvents: MenuIconEventList): void {
    _childEvents.push(...this._reportMenuItems);
  }

  async buildReportMenuItems(): Promise<MenuIconEventList> {
    const childEvents: MenuIconEventList = [];
    super.addReportMenuItems(childEvents);

    //[0] Should Render Template
    //[1] Template to Render
    //[2] Disable Generate Report action
    const getReportButtonTemplate = async (
      documentSubType: DocumentSubType
    ): Promise<[boolean, TemplateResult, boolean]> => {
      let src: string | null = '#';

      let caption: string;

      switch (documentSubType) {
        case DocumentSubType.ClientQuoteAPDF:
          caption = tlang`Full Detail`;
          break;
        case DocumentSubType.ClientQuoteBNoItemPricePDF:
          caption = tlang`No Line Item Price`;
          break;
        case DocumentSubType.ClientQuoteCNoItemPriceNoDimPDF:
          caption = tlang`With Item Price, No Dimensions`;
          break;
        case DocumentSubType.ClientQuoteGNoPricePDF:
          caption = tlang`No Price`;
          break;
        case DocumentSubType.DealerQuoteEIternalPDF:
          caption = tlang`Dealer Cost`;
          break

        // Not used on the site
        case DocumentSubType.DealerQuoteHIternalPDF:
          caption = tlang`Dealer Internal View, Multiplier`;
          break;

        case DocumentSubType.ClientQuoteSummaryPDF:
          caption = tlang`Summary`;
          break;

        default:
          throw Error('Not a quote report request');
      }

      if (
        this.quoteContainer.quote?.state === QuoteState.Issued ||
        this.quoteContainer.quote?.state === QuoteState.Accepted ||
        this.quoteContainer.quote?.state === QuoteState.Accepted_AssignedToReviewer ||
        this.quoteContainer.quote?.state === QuoteState.Approved ||
        this.quoteContainer.quote?.state === QuoteState.Accepted_RefusedBySupplier ||
        this.quoteContainer.quote?.state === QuoteState.Rejected ||
        this.quoteContainer.quote?.state === QuoteState.Cancelled ||
        this.quoteContainer.quote?.state === QuoteState.Lapsed
      ) {
        src = await getPermanentProjectDocumentURL(
          cache().projectResourceLink.getLocalData(this.quoteContainer.quoteId)?.projectId ?? emptyGuid,
          this.quoteContainer.quoteId,
          ResourceType.Quote,
          documentSubType,
          true
        );

        if (!src) {
          src = '#';
          caption = tlang`No generated ${caption}`;
        }

        return src === '#'
          ? [false, html`${caption}`, true]
          : [
              true,
              html` <webmodule-button variant="text" class="report-dropdown-button" href="${src}" target="_blank">${caption}</webmodule-button>`,
              true
            ];
      }

      return [this.quoteContainer.quote?.state === QuoteState.Active, html`${caption}`, false];
    };

    //New Reports
    const quoteReportATemplate = await getReportButtonTemplate(DocumentSubType.ClientQuoteAPDF);
    const quoteReportBTemplate = await getReportButtonTemplate(DocumentSubType.ClientQuoteBNoItemPricePDF);
    const quoteReportCTemplate = await getReportButtonTemplate(DocumentSubType.ClientQuoteCNoItemPriceNoDimPDF);
    const quoteReportGTemplate = await getReportButtonTemplate(DocumentSubType.ClientQuoteGNoPricePDF);
    const quoteReportClientSummaryTemplate = await getReportButtonTemplate(DocumentSubType.ClientQuoteSummaryPDF);
    const quoteReportDealerCost = await getReportButtonTemplate(DocumentSubType.DealerQuoteEIternalPDF);

    const generateReportA = {
      caption: () => quoteReportATemplate[1],
      event: async () => {
        if (!quoteReportATemplate[2]) return await this.generateReportV2(DealerReport.ReportA);

        return false;
      }
    };
    const generateReportB = {
      caption: () => quoteReportBTemplate[1],
      event: async () => {
        if (!quoteReportBTemplate[2]) return await this.generateReportV2(DealerReport.ReportB);

        return false;
      }
    };
    const generateReportC = {
      caption: () => quoteReportCTemplate[1],
      event: async () => {
        if (!quoteReportCTemplate[2]) return await this.generateReportV2(DealerReport.ReportC);

        return false;
      }
    };
    const generateReportG = {
      caption: () => quoteReportGTemplate[1],
      event: async () => {
        if (!quoteReportGTemplate[2]) return await this.generateReportV2(DealerReport.ReportG);

        return false;
      }
    };
    const generateReportClientSummary = {
      caption: () => quoteReportClientSummaryTemplate[1],
      event: async () => {
        if (!quoteReportClientSummaryTemplate[2]) return await this.generateReportV2(DealerReport.ReportJ);

        return false;
      }
    };
    const generateReportDealerCost = {
      caption: () => quoteReportDealerCost[1],
      event: async () => {
        if (!quoteReportDealerCost[2]) return await this.generateReportV2(DealerReport.ReportE);

        return false;
      }
    };

    // New Client Reports
    if (quoteReportATemplate[0]) childEvents.push(generateReportA);
    if (quoteReportBTemplate[0]) childEvents.push(generateReportB);
    if (quoteReportCTemplate[0]) childEvents.push(generateReportC);
    if (quoteReportGTemplate[0]) childEvents.push(generateReportG);
    if (quoteReportClientSummaryTemplate[0]) childEvents.push(generateReportClientSummary);
    if (quoteReportClientSummaryTemplate[0]) childEvents.push(generateReportDealerCost);

    const buttons = this.getStandardButtons();
    if (!this.isLockedFromUse)
      switch (this.quoteContainerManager.quote.state) {
        case QuoteState.Active:
          if (this._canCreateShopDrawingPreview) childEvents.push(buttons.previewShopDrawing);
          break;
        case QuoteState.SupplierReviewPending:
          if (this._canCreateShopDrawingPreview) childEvents.push(buttons.previewShopDrawing);
          break;
        default:
          break;
      }

    //No permanent document
    if (this._permanentShopDrawingUrl) {
      childEvents.push({
        caption: () =>
          html` <webmodule-button variant="text" class="report-dropdown-button" href="${this._permanentShopDrawingUrl}" target="_blank">
            ${tlang`!!shopdrawing!!`}
          </webmodule-button>`,
        event: async () => false
      });
    }

    return childEvents;
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    this.quoteContainerManager.afterSave.push(async () => {
      const siblingIds = this.quoteContainerManager.siblings?.map(x => x.quoteId) ?? [];
      await Promise.all([
        this.prlCache.updateLocal(this.quote.id, ...siblingIds),
        cache().quote.updateLocal(this.quote.id, ...siblingIds)
      ]);
      await this.loadExternalRelatedData();
    });
    await this.prlCache.get(this.quote.id);
    await this.quoteContainerManager.needsQuote();
    if (
      currentUserClaims().isAgent &&
      this.quoteContainerManager.quoteState === QuoteState.Accepted &&
      !this.quote.supplierApproved
    ) {
      //we are a reviewer coming to look at the item.
      await this.quoteContainerManager.quoteStateChange(QuoteState.Accepted_AssignedToReviewer);
    }
    await this.loadExternalRelatedData();
  }

  protected async internalDeleteQuoteItem(quoteItemContainer: QuoteItemContainer): Promise<void> {
    if (!isSupplierUsingSSI(this.quote.supplierId)) {
      await super.internalDeleteQuoteItem(quoteItemContainer);
      return;
    }

    const processSSIDelete = async () => {
      const qm = this.quoteContainerManager;
      if ((qm.isV6 && isFrame(quoteItemContainer.item)) || isSpecialItem(quoteItemContainer.item)) {
        const foundPage = this.findIfPageAvailable(quoteItemContainer);
        if (foundPage) await this.closePageIfAvailable(quoteItemContainer);

        const informationDispatcher = new InformationDispatcher();
        const setInfo = async (s: string, header = 'Preparing to delete') => {
          await informationDispatcher.setInformation(`# ${header}

                + ${s}`);
        };
        await setInfo(tlang`getting updates from %%supplier%%`);

        const saveModal = new SaveWorkflowModal(
          tlang`Deleting ${quoteItemContainer.item.title}`,
          informationDispatcher,
          3
        );

        //we dont want to wait for the modal to finish.
        const modalState = await saveModal.show();
        await modalState.onShow;
        try {
          //build an ssi processor that will build the quote exluding the deleted item, so that we get data
          //based on its non-existence
          const ssiProcessor = this.quoteContainerManager.ssiProcessor([quoteItemContainer.item.id]);
          const ssiInputs = await ssiProcessor.processSSI();
          await setInfo(tlang`%%supplier%% processing complete. 
                
                + sending updates to server`);
          //TODO when deleting an item, we are going to need to hook in the SSI processing
          if (await qm.deleteQuoteItem(quoteItemContainer, ssiInputs)) {
            await setInfo(tlang`%%quote-item%% has been deleted`);
          } else {
            await setInfo(tlang`Unable to delete the selected %%quote-item%%`);
            fireQuickErrorToast(tlang`Unable to delete the selected %%quote-item%%`);
          }
        } finally {
          saveModal?.done();
          await saveModal?.hideModal();
        }
      } else {
        await super.internalDeleteQuoteItem(quoteItemContainer);
      }
    };
    await processSSIDelete();
  }

  async produceShopDrawing() {
    if (this.quoteContainerManager.isV6)
      await V6SupplierQuoteBase.generateShopDrawing(
        this.branchQuote?.branchId ?? emptyGuid,
        this.quote.id,
        await this.quoteContainerManager.projectId()
      );
  }
  async produceSupplierQuote() {
    if (this.quoteContainerManager.isV6)
      if (!v6SupportsVersion(v6VersionMap.hasCheckSupplierQuote)) {
        await information(tlang`Frame Config v1.0.58 required for this feature`);
      }
    await V6SupplierQuoteBase.generateTempQuote(
      this.branchQuote?.branchId ?? emptyGuid,
      this.quote.id,
      this.quote.supplierId
    );
  }

  /**
   *
   * @returns override this to add logic
   */
  async mustValidateItems(): Promise<boolean> {
    return await this.providerInterface.mustValidateItems(this.quoteContainerManager);
  }

  /**
   * override this to add logic
   */
  async validateOutOfDateQuoteItems() {
    const doReValidate = this.isDataReadonly();

    const result = doReValidate
      ? await this.providerInterface.reValidateOutOfDateQuoteItemsOnLockedQuote(this.quoteContainerManager)
      : await this.providerInterface.validateOutOfDateQuoteItems(this.quoteContainerManager);
    await this.dispatchUiChanged();
    return result;
  }
  protected async loadExternalRelatedData() {
    this._orderId = await this.quoteContainerManager.purchaseOrderId();
    this._projectId = await this.quoteContainerManager.projectId();
    this._projectCacheDisplay = (await this.projectCache.get(this._projectId))?.displayValue;
    this._orderCacheDisplay = (await this.purchaseOrderCache.get(this._orderId))?.displayValue;
    this._canCreateShopDrawingPreview = await this.canCreateShopDrawingPreview();

    this._permanentShopDrawingUrl = await getPermanentProjectDocumentURL(
      cache().projectResourceLink.getLocalData(this.quoteContainerManager.quote.id)?.projectId ?? emptyGuid,
      this.quoteContainerManager.quote.id,
      ResourceType.Quote,
      DocumentSubType.QuoteShopDrawing,
      true
    );
    this._reportMenuItems = await this.buildReportMenuItems();
    const supplierTitle = async () => {
      const isMulti = isMultiSupplier();
      const supplierId = this.quoteContainer.quote?.supplierId ?? emptyGuid;
      const supplierName =
        isMulti && this.quoteContainer.quote
          ? ((await getQuoteSuppliers(false)).find(x => x.supplierId === supplierId)?.description ?? '')
          : '';

      return isMulti
        ? html`
            <div>
              <span class="subtitle text-secondary fs-3">[${supplierName}]</span>
            </div>
          `
        : html``;
    };
    this._supplierTitle = await supplierTitle();
    this.dispatchUiChanged();
  }
  public getTitle(): Snippet {
    //override and do not use this.title

    const quote = this.quoteContainer.quote;
    if (!quote) return html``; // will never happen

    const projectId = this._projectId;
    const orderId = this._orderId;

    const projectTemplate = validId(projectId)
      ? html`<a href="${resolveURL(resourceProject, projectId)}" class="btn btn-sm btn-light"
          >${this._projectCacheDisplay ?? '???-???-???'}</a
        >`
      : html``;
    const orderTemplate = validId(orderId)
      ? html`<a href="${resolveURL(resourcePurchaseOrder, orderId)}" class="btn btn-sm btn-light"
          >${this._orderCacheDisplay ?? '???-???-???'}</a
        >`
      : html``;

    return html`
      ${getQuoteTitle(quote, this.quoteContainerManager.quoteState)}
      <div class="title-links">${projectTemplate} ${orderTemplate}</div>
      ${this._supplierTitle}
    `;
  }

  protected async canCreateShopDrawingPreview(): Promise<boolean> {
    return (await this.quoteContainerManager.projectId()) !== emptyGuid && this.quoteContainerManager.hasFrames;
  }

  protected getQuoteViewChildFactory(): QuoteViewChildFactory {
    return new FranchiseeQuoteViewChildFactory(this);
  }

  async valid(_state: QuoteState): Promise<boolean> {
    return await this.checkValidations();
  }

  protected async canAddItemFrame(): Promise<boolean> {
    const qm = this.quoteContainerManager as FranchiseeQuoteContainerManager;
    if (isEmptyOrSpace(qm.branchQuote.clientTypeId)) {
      await information(
        tlang`Pricing is based on the %%payment-profile%% which is not set <br>Please set the %%payment-profile%% before adding !!frame!!`
      );
      return false;
    }
    return true;
  }

  protected includeShippingLineItem(): boolean {
    return isValidV6ConfigVersion()
      ? v6Config().getSupplier(this.quote.supplierId).options?.['includeShipping'] === 'true'
      : false;
  }
  protected hasSupplierGlassView(): boolean {
    return isValidV6ConfigVersion()
      ? v6Config().getSupplier(this.quote.supplierId).options?.['useQuoteGlass'] === 'true'
      : false;
  }

  protected async onQuoteStateChanged(state: QuoteState): Promise<void> {
    if (
      state == QuoteState.Accepted ||
      state == QuoteState.Approved ||
      state == QuoteState.Cancelled ||
      state == QuoteState.IssuePending ||
      state == QuoteState.Rejected ||
      state == QuoteState.SupplierReviewed
    ) {
      goURL('project', await this.quoteContainerManager.projectId(), 'resources');
    } else if (state == QuoteState.Active) {
      this.pageControl.addPage(this.createSupportPage(), false);
    }
  }
  async canCompleteReview(): Promise<boolean> {
    return await this.quoteContainerManager.canIssueQuote();
  }
  async setActiveTabByHash(): Promise<void> {
    const hashId = window.location.hash;
    if (hashId.startsWith('#support_')) {
      const conversationId = hashId.substring(9);

      const conversationCache = cache().quoteItemConversation;
      const specialOrderItems = this.quoteItems.filter(qi => isSpecialItem(qi));

      const conversaionLinks = await conversationCache.getMany(specialOrderItems.map(x => x.id));
      let needsSupportRequestOpened = true;
      if (conversaionLinks) {
        const qic = conversaionLinks.find(cl => {
          return cl.data.items.find(c => c.conversationId == conversationId) !== undefined;
        });
        if (qic) {
          needsSupportRequestOpened = false;
          await this.runQuoteItemAction(this.quoteContainerManager.quoteItemContainer(qic.id), quoteItemAction.edit);
        }
      }
      if (needsSupportRequestOpened) {
        const result = await getApiFactory().franchisee().getBranchQuoteSupport({
          quoteSetId: this.quote.quoteSetId,
          branchQuoteSupportId: null,
          quoteId: this.quote.id
        });
        if (result) {
          const supportRequest = result.items.find(i => i.conversationId == conversationId);
          if (supportRequest) {
            await this.openSupportTicket(supportRequest);
          }
        }
      }
    } else {
      await super.setActiveTabByHash();
    }
  }
}

export class FranchiseeQuoteView extends QuoteView {
  protected createView(): QuoteDataEntryView {
    return new FranchiseeQuoteDEView(this.options, this);
  }
}
