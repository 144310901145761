import { ClientSummaryPickerBrowser } from './client-summary-picker';
import { html } from 'lit';
import { ModalPickerDialog, ModalPickerDialogOptions } from '../../../webmodule-common/other/ui/modal-picker-base';
import { NullPromise } from '../../../webmodule-common/other/ui/events';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { ViewClientSummary } from '../../api/dealer-api-interface-client';

export async function ClientPicker(): NullPromise<ViewClientSummary> {
  return new Promise<ViewClientSummary | null>((resolve, reject) => {
    const picker = new ClientBrowserPicker({
      onSelect: (selected: ViewClientSummary | null) => {
        resolve(selected);
      },
      // eslint-disable-next-line prefer-promise-reject-errors
      onCancel: () => reject(),
      title: tlang`Select a %%client%%`
    });
    picker.showModal();
  });
}

class ClientBrowserPicker extends ModalPickerDialog<ViewClientSummary> {
  private comms: ClientSummaryPickerBrowser;

  constructor(options: ModalPickerDialogOptions<ViewClientSummary>) {
    super(options);

    this.comms = new ClientSummaryPickerBrowser({
      title: () => options.title ?? '',
      onClientSelect: clientSummary => this.select(clientSummary)
    });
  }

  bodyTemplate() {
    return html`${this.comms}`;
  }

  footerTemplate() {
    return html` <webmodule-button size="small" variant="default" @click=${() => this.hideModal()}
      >${tlang`Close`}
    </webmodule-button>`;
  }
}
