import { BranchQuote } from '../../api/dealer-api-interface-franchisee';
import { currentQuoteSupplierProvider } from '../../quotes/data/quoteSupplierProvider';
import { emptyGuid, newGuid } from '../../../webmodule-common/other/api/guid';
import { FranchiseeQuoteContainer } from './data/franchisee-quote-container';
import { getApiFactory } from '../../api/api-injector';
import { getQuoteSuppliers } from '../../quotes/quote-service';
import { InputCreateQuote, QuoteType } from '../../api/dealer-api-interface-quote';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { localDateToServer } from '../../../webmodule-common/other/datetime-converter';
import { QuoteContainerManager } from '../../quotes/data/quote-container';
import { showError } from '../../../webmodule-common/other/ui/show-error';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { toJsonStr } from '../../../webmodule-common/other/blob-converters';
import { userDataStore } from '../common/current-user-data-store';
import { v6ConfigSupplierOptions } from '../../v6config/v6config';

export interface createFranchiseeQuoteOptions {
  supplierId: string;
  owner: string;
}

export async function createNewQuote(options: createFranchiseeQuoteOptions): Promise<FranchiseeQuoteContainer | null> {
  if (!isEmptyOrSpace(options.owner)) {
    try {
      //Shane: For initial release no modal, just create a quote
      //Kyley - Defaults here dont matter. defaults will be updated
      //via ui changing client settings etc

      const serviceProviderData = await QuoteContainerManager.createQuoteServiceProviderData(
        currentQuoteSupplierProvider,
        options.supplierId
      );
      const supplieroptions = v6ConfigSupplierOptions(options.supplierId);
      const inputCreateQuote: InputCreateQuote = {
        id: newGuid(), //change this to use server side guid fetch
        quoteType: QuoteType.Quote,
        title: tlang`New %%quote%%`,
        quoteOwnerId: options.owner,
        validUntilDate: null,
        termsAndConditions: '',
        excludeShippingLineItem: !supplieroptions.includeShipping,
        shippingTitle: tlang`Shipping`,
        shippingDescription: tlang`Total Shipping Cost`,
        supplierId: options.supplierId,
        serviceProvider: currentQuoteSupplierProvider,
        serviceProviderData: toJsonStr(serviceProviderData) ?? null,
        taxPercentage: userDataStore.defaultTaxRate
      };

      if (!userDataStore.defaultBranch) await userDataStore.loadCoreDetails();
      if (!userDataStore.defaultBranch) {
        await showError(
          tlang`cannot create %%quote%% because you are not assigned to a %%branch%%, please contact support`
        );
        return null;
      }

      const quoteResult = await getApiFactory().quote().createQuote(inputCreateQuote);

      //TODO - we are only going to allow one kind of provider per quote
      // and only allow one kind of supplier per quote
      // so at some point, we need to add to the Quote/or Branch Quote
      // some limitation fields.. is ProviderType "v6"/"v7" and "supplierref"

      if (!quoteResult) return null;
      const branchQuote: BranchQuote = {
        branchId: userDataStore.defaultBranch.id,
        globalSupplierSupplierId: quoteResult.quote.supplierId,
        quoteSetId: quoteResult.quote.quoteSetId,
        //Use the exact same id for the branch quote. makes it easy to extend
        id: quoteResult.quote.id,
        clientId: emptyGuid,
        clientName: '',
        clientTypeId: emptyGuid,
        contactId: emptyGuid,
        clientTypeName: '',
        contactName: '',
        dateCreated: localDateToServer(new Date()),
        recordVersion: ''
      };

      //if there is any reason we can't create this, we wont worry about it now, if we already have the quote
      //created. we will just attempt to create it later. if it cant be done later, it is probably
      //a system failure.
      const branchQuoteResult = await getApiFactory().franchisee().createBranchQuote({ branchQuote: branchQuote });

      //construct new quote container for an empty new quote
      return new FranchiseeQuoteContainer(
        quoteResult.quote.id,
        quoteResult.quote,
        quoteResult.quotePrice,
        quoteResult.quotePresentation,
        null,
        null, //we want to force load any potential server generated items
        null,
        null,
        null,
        branchQuoteResult?.branchQuote ?? branchQuote,
        serviceProviderData,
        true
      );
    } catch {
      return null;
    }
  } else {
    //owner is defined as the Branch of the user.
    await showError(
      tlang`cannot create %%quote%% because you are not assigned to a %%branch%%, please contact support`
    );
    return null;
  }
}

export async function checkSuppliersIsOnline(supplierId: string): Promise<boolean> {
  //force a reload of the supplier list when we want to create quotes, to check the online state of things.
  const suppliers = await getQuoteSuppliers(true);
  const supplier = suppliers.find(x => x.supplierId === supplierId);
  return supplier?.online ?? false;
}
