import { allowPageControlTabChange, isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { buttonsSaveCancel } from '../../../webmodule-common/other/ui/modal-confirmation';
import { ClientApi } from '../../api/client-api';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { ContactContainer, ContactContainerManager } from '../data/contact-container-manager';
import { ContactDetailView } from './contact-detail-view';
import { customElement } from 'lit/decorators.js';
import { DataEntryOwner } from '../../../webmodule-common/other/ui/DataEntryOwner';
import { DataEntryPageControlView, ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { EventSnippet, EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { getApiFactory } from '../../api/api-injector';
import { html, TemplateResult } from 'lit';
import {
  MenuIconOption,
  PageControl,
  PageControlOptions,
  PageManager
} from '../../../webmodule-common/other/ui/page-control';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { validId } from '../../../webmodule-common/other/ui/string-helper-functions';

export interface ContactViewOptions {
  contact: ContactContainer;
  title: EventSnippet;
}

@customElement('wm-contactdataentryview')
export class ContactDataEntryView extends DataEntryPageControlView {
  clientApi: ClientApi = getApiFactory().client();
  protected contactContainer: ContactContainer;
  protected contactContainerManager: ContactContainerManager;
  protected readonly eventTitle: EventSnippet;
  protected readonly detailView: ContactDetailView;

  constructor(options: ContactViewOptions, owner: DataEntryOwner) {
    super(owner);
    this.eventTitle = options.title;

    this.contactContainer = options.contact;
    this.contactContainerManager = this.contactContainerManagerFactory();

    this.detailView = this.contactDetailViewFactory();
  }

  /**
   * inherited;
   * @returns
   */
  public async afterConstruction() {
    await this.contactContainerManager.needsContact();

    //this will create the pagecontrol
    await super.afterConstruction();
    this.buildContactActionMenu();
  }

  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      pages.push(this.detailView?.createPageManager());
      return pages;
    };
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  /**
   * inherited;
   * @returns
   */
  public internalDataChanged(): boolean {
    return this.contactContainerManager.changed();
  }

  /**
   * inherited;
   * @returns
   */
  public isDataReadonly(): boolean {
    return this.contactContainerManager.isReadonly();
  }

  /**
   * inherited;
   * @returns
   */
  public async prepareForSave(): Promise<void> {
    if (this.isDataReadonly()) return;

    this.detailView.prepareForSave();
  }

  /**
   * inherited;
   * @returns
   */
  public getTitle(): Snippet {
    return this.eventTitle();
  }

  /**
   * inherited;
   * @returns
   */
  async allowPageSwitch(): Promise<boolean> {
    return await allowPageControlTabChange(this.getAutoSavePromptOptions());
  }

  public async saveAndClose(): Promise<boolean> {
    if (this.isDataReadonly()) return false;

    return await this.tryClose();
  }

  /**
   * inherited;
   * @returns
   */
  protected bodyTemplate(): EventTemplate {
    this.buildContactActionMenu();
    return super.bodyTemplate();
  }

  protected isNew(): boolean {
    return !validId(this.contactContainer.contactId);
  }

  /**
   * inherited;
   * @returns
   */
  protected async internalSaveData(): Promise<boolean> {
    return await this.contactContainerManager.save(isAutoSaving());
  }

  /**
   * inherited;
   * @returns
   */
  protected getValidationErrors() {
    const errors = this.detailView.getValidationErrors();
    return errors;
  }

  protected contactDetailViewFactory() {
    return new ContactDetailView(this, {
      contactManager: this.contactContainerManager
    });
  }

  protected contactContainerManagerFactory(): ContactContainerManager {
    return new ContactContainerManager(this.contactContainer, this.eventTitle, this.clientApi);
  }

  private buildContactActionMenu() {
    const menuIcons: MenuIconOption[] = [];
    if (!this._pageControl) return;

    const save = {
      event: async () => await this.saveAndClose(),
      caption: () => tlang`Save %%contact%%`
    };

    if (!isAutoSaving()) menuIcons.push(save);

    this.pageControl.setMenuIcons(menuIcons);
  }
}

export class ContactView extends ModalViewBase {
  view: ContactDataEntryView | null = null;
  options: ContactViewOptions;

  constructor(options: ContactViewOptions) {
    super();
    this.options = options;
  }

  /**
   * inherited
   * @returns
   */
  protected get modalSize() {
    return 'modal-lg';
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    return (await this.view?.canClose()) ?? true;
  }

  async afterConstruction(): Promise<void> {
    this.view = await constructAsync(this.createView());
  }

  /**
   * inherited
   * @returns
   */
  protected getTitle(): Snippet {
    return this.view?.getTitle() ?? '';
  }

  /**
   * inherited
   * @returns
   */
  protected footerTemplate(): TemplateResult | null {
    const buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, () => this.view?.saveAndClose(), buttons.cancel);
  }

  protected createView(): ContactDataEntryView {
    return new ContactDataEntryView(this.options, this);
  }

  protected bodyTemplate(): EventTemplate {
    return html`${this.view?.ui}`;
  }

  protected renderCloseButtonTemplate(): boolean {
    return false;
  }
}
