import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../../webmodule-common/other/ui/modal-confirmation';
import { cache } from '../../cache-impl/cache-registry';
import { customElement } from 'lit/decorators.js';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import {
  EventResource,
  ProjectResourceView,
  ProjectResourceViewOptions
} from '../../../projects/views/project-resource-view';
import { EventTemplate, Snippet } from '../../../../webmodule-common/other/ui/events';
import { fireQuickErrorToast } from '../../../../webmodule-common/other/toast-away';
import { getApiFactory } from '../../../api/api-injector';
import {
  getPurchaseOrderNumberFormatted,
  getPurchaseOrderStatus
} from '../../../purchase-orders/data/purchase-order-helper-functions';
import { getQuoteContainer, getQuoteContainerManager } from '../../quotes/quote-ui-adapter';
import {
  getQuoteNumberFormatted,
  getQuoteStatus,
  quoteTypeToString
} from '../../../quotes/data/quote-helper-functions';
import { html } from 'lit';
import { ItemReference } from '../../../../webmodule-common/cache/definitions/cache-item-reference';
import { launchQuote, resourceQuote } from '../../../quotes/ui/launcher';
import { LitTableWrapper } from '../../../../webmodule-common/other/ui/littable-view';
import { moneyToTemplateResult } from '../../../../webmodule-common/other/currency-formatter';
import { ProjectContainerManager } from '../../../projects/data/project-container';
import { ProjectResourceLink } from '../../cache-impl/resource-link';
import { ProjectState, Resource, ResourceType } from '../../../api/dealer-api-interface-project';
import { PurchaseOrder, PurchaseOrderState } from '../../../api/dealer-api-interface-franchisee';
import { QuoteSetSiblingManager } from '../../quotes/data/quote-state-engine';
import { QuoteState, ViewQuoteSummary } from '../../../api/dealer-api-interface-quote';
import { refreshButton } from '../../../../webmodule-common/other/ui/command-action';
import { RequestPage, ResultPaginated } from '../../../../webmodule-common/other/ui/RequestPage';
import { resolveURL } from '../../../../webmodule-common/other/ui/resource-resolver';
import { resourcePurchaseOrder } from '../../../purchase-orders/ui/launcher';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef,
  WebmoduleSelectEvent
} from '../../../../webmodule-common/components/src/webmodule-components';
import { when } from 'lit/directives/when.js';

interface FranchiseeProjectResourceTableOptions {
  projectManager: ProjectContainerManager;
}

interface FranchiseProjectResourceQuoteTableOptions extends FranchiseeProjectResourceTableOptions {
  copyResourceEvent: EventResource;
}

@customElement('wm-franchiseeprojectresourcequotetable')
export class FranchiseeProjectResourceQuoteTable extends LitTableWrapper<Resource> {
  projectManager: ProjectContainerManager;
  quoteCache = cache().quote;
  copyResourceEvent: EventResource;

  constructor(options: FranchiseProjectResourceQuoteTableOptions) {
    super();
    this.projectManager = options.projectManager;
    this.copyResourceEvent = options.copyResourceEvent;
  }

  public async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<Resource>> {
    await this.projectManager.needsProject();
    const items = this.projectManager.container.resources?.filter(x => x.typeOf == ResourceType.Quote);
    if (!items)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    const quoteKeys = items.map(x => x.resourceId ?? emptyGuid) ?? [];

    //if there are any other callbacks we need to do, add them to the
    //all array which will let them run at the same time
    await Promise.all([this.quoteCache.preFetch(quoteKeys)]);

    items.sort((a1: Resource, a2: Resource) => {
      const s1 = this.getQuoteSummary(a1.resourceId);
      const s2 = this.getQuoteSummary(a2.resourceId);
      const t1 = getQuoteNumberFormatted(s1);
      const t2 = getQuoteNumberFormatted(s2);
      return t1.localeCompare(t2);
    });
    return {
      count: items.length,
      pageCount: 1,
      pageIndex: 0,
      pageSize: items.length,
      results: items
    };
  }

  public getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`Date`,
        fieldName: 'resourceId',
        classes: 'colpx-50 colpxmax-120 project-quote-created-date',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          const summary = this.getQuoteSummary(resource.resourceId);

          if (!summary) return '';

          const dt = new Date(summary.dateCreated);
          return `${dt.toLocaleDateString()}`;
        }
      },
      {
        title: tlang`%%quote%% No.`,
        fieldName: 'resourceId',
        classes: 'colpx-50 colpxmax-180 project-quote-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          return this.quoteLink(resource, getQuoteNumberFormatted(this.getQuoteSummary(resource.resourceId)));
        }
      },
      {
        title: tlang`%%quote%% Title`,
        fieldName: 'resourceId',
        classes: 'colpx-50 project-quote-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          return this.quoteLink(resource, this.getQuoteSummary(resource.resourceId)?.title ?? '');
        }
      },
      {
        title: tlang`Type`,
        fieldName: 'resourceId',
        classes: 'colpx-50 colpxmax-160 project-quote-type',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          const summary = this.getQuoteSummary(resource.resourceId);

          if (!summary) return '';

          return quoteTypeToString(summary.quoteType);
        }
      },
      {
        title: tlang`Last Modified`,
        fieldName: 'lastModifiedDate',
        classes: 'colpx-50 colpxmax-180 project-quote-last-modified-date no-pseudo',
        sortable: false,
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          const summary = this.getQuoteSummary(resource.resourceId);

          if (!summary || !summary.lastModifiedDate) return '';

          const dt = new Date(summary.lastModifiedDate);
          return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
        }
      },
      {
        title: tlang`Status`,
        fieldName: 'resourceId',
        classes: 'colpx-50 colpxmax-160 project-quote-state no-pseudo',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          const summary = this.getQuoteSummary(resource.resourceId);

          if (!summary) return '';

          return getQuoteStatus(summary.state, true);
        }
      },
      {
        title: tlang`Amount`,
        fieldName: 'resourceId',
        classes: 'colpx-50 colpxmax-120 dt-right project-quote-price no-pseudo',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          const price = this.getQuotePrice(resource);
          return price ? moneyToTemplateResult(price) : '';
        }
      },
      {
        title: html` <webmodule-icon library="fa" name="fas-bars"></webmodule-icon>`,
        fieldName: 'resourceId',
        sortable: false,
        classes: 'colpx-30 colpxmax-50 item-menu',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          return this.ellipsisMenu(resource);
        }
      }
    ];
  }

  ellipsisMenu(row: Resource) {
    const summary = this.getQuoteSummary(row.resourceId);

    const template = html` <webmodule-dropdown
      placement="bottom-end"
      hoist
      @webmodule-select=${(e: WebmoduleSelectEvent) => this.onMenuItemSelected(e, row)}
    >
      <webmodule-icon-button slot="trigger" library="fa" name="fas-bars"></webmodule-icon-button>
      <webmodule-menu>
        ${when(
          summary,
          () =>
            html` <webmodule-menu-item class="action-copy" value="copy">
              <webmodule-icon slot="prefix" library="fa" name="fas-copy"></webmodule-icon>
              Copy
            </webmodule-menu-item>`
        )}
      </webmodule-menu>
    </webmodule-dropdown>`;
    return template;
  }

  async onMenuItemSelected(event: WebmoduleSelectEvent, resource: Resource) {
    if (event.detail.item.value == 'copy') {
      await this.copyResourceEvent(resource.resourceId);
    }
  }

  public getQuoteSummary(id: string) {
    const quoteSummary = this.quoteCache.getLocalData(id)?.quoteSummary;
    return quoteSummary ? (quoteSummary as ViewQuoteSummary) : null;
  }

  /**
   * Checks to see if any resources held by this table are pending a further state transition.
   * @returns
   */
  public hasPendingResources(): boolean {
    const items = this.projectManager.container.resources?.filter(x => x.typeOf == ResourceType.Quote);
    if (items) {
      const quoteKeys = items.map(x => x.resourceId ?? emptyGuid);
      for (const quoteKey of quoteKeys) {
        const quoteSummary = this.getQuoteSummary(quoteKey);
        const result = quoteSummary && quoteSummary.state === QuoteState.IssuePending;
        if (result) return true; // no need to continue checking if any item is still pending
      }
    }
    return false;
  }

  /**
   * Clears the resources held by this table from the cache.
   */
  public async clearResources(): Promise<void> {
    const items = this.projectManager.container.resources?.filter(x => x.typeOf == ResourceType.Quote);
    if (items) {
      const quoteKeys = items.map(x => x.resourceId ?? emptyGuid);
      await this.quoteCache.updateLocal(...quoteKeys);
    }
  }

  protected quoteLink(row: Resource, value: string) {
    return html`<a
      class="quote-link"
      href="${resolveURL(resourceQuote, row.resourceId)}"
      data-quoteid="${row.resourceId}"
      >${this.htmlEncode(value)}</a
    >`;
  }

  private getQuotePrice(row: Resource) {
    return this.getQuoteSummary(row.resourceId)?.calculatedGrossTotal;
  }
}

@customElement('wm-franchiseeprojectresourcepurchaseordertable')
export class FranchiseeProjectResourcePurchaseOrderTable extends LitTableWrapper<Resource> {
  projectManager: ProjectContainerManager;
  private quoteCache = cache().quote;
  private purchaseOrderCache = cache().purchaseOrder;

  private linkCache = cache().projectResourceLink;

  constructor(options: FranchiseeProjectResourceTableOptions) {
    super();
    this.projectManager = options.projectManager;
  }

  public async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<Resource>> {
    await this.projectManager.needsProject();
    const items = this.projectManager.container.resources?.filter(x => x.typeOf == ResourceType.PurchaseOrder);
    if (!items)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    const poKeys = items.map(x => x.resourceId ?? emptyGuid) ?? [];

    await this.purchaseOrderCache
      .preFetch(poKeys)
      .then(async () => {
        return await this.linkCache.getMany(poKeys);
      })
      .then(async (data: ItemReference<ProjectResourceLink>[] | null) => {
        const quoteIds = data?.map(x => x.data.quoteId) ?? [];
        return this.quoteCache.preFetch(quoteIds);
      });
    /*
        items.sort((item1, item2) => {
const x=1

        })
        */
    return {
      count: items.length,
      pageCount: 1,
      pageIndex: 0,
      pageSize: items.length,
      results: items
    };
  }

  public getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%purchase-order%% No.`,
        fieldName: 'resourceId',
        classes: 'colpx-50 colpxmax-300 project-order-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          return this.getPurchaseOrderLink(
            resource,
            getPurchaseOrderNumberFormatted(this.getPurchaseOrder(resource.resourceId))
          );
        }
      },
      {
        title: tlang`%%quote%% No.`,
        fieldName: 'resourceId',
        classes: 'colpx-50 project-order-quote-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          return this.quoteLink(
            this.getQuote(resource.resourceId)?.id ?? '',
            getQuoteNumberFormatted(this.getQuote(resource.resourceId))
          );
        }
      },
      {
        title: tlang`Last Modified`,
        fieldName: 'lastModifiedDate',
        classes: 'colpx-50 colpxmax-180 project-order-last-modified-date no-pseudo',
        sortable: false,
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          const po = this.getPurchaseOrder(resource.resourceId);

          if (!po) return '';

          const dt = new Date(po.lastModifiedDate ?? po.dateCreated);
          return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
        }
      },
      {
        title: tlang`Status`,
        fieldName: 'resourceId',
        classes: 'colpx-50 colpxmax-160 project-order-state no-pseudo',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          const summary = this.getPurchaseOrder(resource.resourceId);

          if (!summary) return '';

          return getPurchaseOrderStatus(summary.state, true);
        }
      },
      {
        title: tlang`Amount`,
        fieldName: 'resourceId',
        classes: 'colpx-50 colpxmax-170 dt-right project-order-price no-pseudo',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const resource = item as Resource;
          const price = this.getPrice(resource);
          return moneyToTemplateResult(price ?? 0);
        }
      }
    ];
  }

  public getPurchaseOrder(id: string) {
    const po = this.purchaseOrderCache.getLocal(id)?.data.purchaseOrder;
    return po ? (po as any as PurchaseOrder) : null;
  }

  public getQuote(poId: string) {
    const qid = this.linkCache.getLocal(poId);
    const quoteSummary = this.quoteCache.getLocal(qid?.data.quoteId)?.data.quoteSummary;
    return quoteSummary ? (quoteSummary as ViewQuoteSummary) : null;
  }

  /**
   * Checks to see if any resources held by this table are pending a further state transition.
   * @returns
   */
  public hasPendingResources(): boolean {
    const items = this.projectManager.container.resources?.filter(x => x.typeOf == ResourceType.PurchaseOrder);
    if (items) {
      const purchaseOrderKeys = items.map(x => x.resourceId ?? emptyGuid);
      // poke each purchase order item until we find one that is still pending
      for (const poKey of purchaseOrderKeys) {
        const poSummary = this.getPurchaseOrder(poKey);
        const result = poSummary && poSummary.state === PurchaseOrderState.IssuedPending;
        if (result) return true; // no need to continue checking if any item is still pending
      }
    }
    return false;
  }

  /**
   * Clears the resources held by this table from the cache.
   */
  public async clearResources(): Promise<void> {
    const items = this.projectManager.container.resources?.filter(x => x.typeOf == ResourceType.PurchaseOrder);
    if (items) {
      const purchaseOrderKeys = items.map(x => x.resourceId ?? emptyGuid);
      await this.purchaseOrderCache.updateLocal(...purchaseOrderKeys);
    }
  }

  protected quoteLink(id: string, value: string) {
    return html`<a class="quote-link" href="${resolveURL(resourceQuote, id)}" data-quoteid="${id}"
      >${this.htmlEncode(value)}</a
    >`;
  }

  private getPrice(row: Resource) {
    const po = this.getPurchaseOrder(row.resourceId);

    if (po) return po.calculatedNetTotal + po.calculatedAdjustmentTotal;

    return null;
  }

  private getPurchaseOrderLink(row: Resource, value: string) {
    return html`<a
      class="purchase-order-link"
      href="${resolveURL(resourcePurchaseOrder, row.resourceId)}"
      data-purchase-order-id="${row.resourceId}"
      >${this.htmlEncode(value)}</a
    >`;
  }
}

@customElement('wm-franchiseeprojectresourceview')
export class FranchiseeProjectResourceView extends ProjectResourceView {
  private quoteTable: FranchiseeProjectResourceQuoteTable;
  private poTable: FranchiseeProjectResourcePurchaseOrderTable;
  private projectCache = cache().project;
  private projectApi = getApiFactory().project();

  constructor(options: ProjectResourceViewOptions) {
    super(options);

    this.quoteTable = this.getResourceQuoteTableFactory();
    this.poTable = this.getResourcePurchaseOrderTableFactory();
  }

  public override async invalidate(): Promise<void> {
    await super.invalidate();
    this.quoteTable.refreshData();
    this.poTable.refreshData();
  }

  public async refreshData(): Promise<void> {
    await super.refreshData();

    await this.updateResources();
    await this.quoteTable.refreshData();
    await this.poTable.refreshData();
  }

  public buttonMenu(): Snippet {
    return html`${refreshButton(async () => {
      await this.refreshData();
    })}`;
  }

  public override async updateResources(): Promise<void> {
    await this.projectManager.reloadResourcesAndDocuments();
    await this.quoteTable.clearResources();
    await this.poTable.clearResources();
  }

  protected getResourceQuoteTableFactory() {
    return new FranchiseeProjectResourceQuoteTable({
      projectManager: this.projectManager,
      copyResourceEvent: async (id: string) => {
        return await this.copyQuote(id);
      }
    });
  }

  protected getResourcePurchaseOrderTableFactory() {
    return new FranchiseeProjectResourcePurchaseOrderTable({
      projectManager: this.projectManager
    });
  }

  protected template(): EventTemplate {
    return html`
      <h2 class="section-header">${tlang`!!quote!!`}</h2>
      <div class="row">${this.quoteTable}</div>

      <h2 class="section-header">${tlang`!!purchase-order!!`}</h2>
      <div class="row">${this.poTable}</div>
    `;
  }

  private async copyQuote(id: string): Promise<boolean> {
    //REFACTOR -> put this logic into the container manager
    const qcm = getQuoteContainerManager(getQuoteContainer(id));
    await qcm.needsQuote();
    const projectState = this.projectManager.project.state;
    const engine = new QuoteSetSiblingManager(() => qcm.siblings ?? [], qcm.quote);

    const isProjectActive = projectState != ProjectState.Cancelled && projectState != ProjectState.Completed;
    if (isProjectActive && !engine.isNewQuoteAllowed) {
      if (
        !(await AskConfirmation(
          tlang`%%quote%% group ${qcm.quote.quoteNumber} cannot accept new copies.
                Do you want to make a new %%quote%% in this %%project%% based on ${getQuoteNumberFormatted(qcm.quote)}`,
          confirmationButtons[ConfirmationButtonType.continueCancel]
        ))
      )
        return false;
    }

    const makeNewQuoteFromOld = async () => {
      qcm.generateQuoteNumberOnSave();
      return qcm.makeCopy();
    };
    const copy = isProjectActive
      ? engine.isNewQuoteAllowed
        ? await qcm.makeAlternative()
        : await makeNewQuoteFromOld()
      : await qcm.makeCopy();
    if (copy) {
      if (isProjectActive) {
        //TODO# when  we implement supplier reviews we will need to be able to execute this quote as an
        //after save event of a quote.
        const resource = await this.projectApi.createProjectResourceReference({
          resourceId: copy.quoteId,
          projectId: this.projectManager.projectId,
          typeOf: ResourceType.Quote,
          parentResourceId: emptyGuid,
          parentTypeOf: ResourceType.None
        });
        if (resource) {
          this.projectCache.updateLocal(this.projectManager.projectId);

          await this.refreshData();
        } else {
          // if we were unable to create the project link, remove the alternative quote
          return await getQuoteContainerManager(copy).deleteQuote();
        }
      }
      return launchQuote(copy.quoteId);
    }
    fireQuickErrorToast(tlang`Unable to copy %%quote%%`);
    return false;
  }
}
