import { tlang } from '../other/language/lang';
import { DevelopmentError } from '../other/development-error';
import { errorList, IErrorList } from '../other/error-list';
import { isNothing, isSomething } from '../other/general/nothing';

export const pricingModelType: { [key: string]: PricingModelType } = {
  margin: 1,
  markup: 2
};

export type PricingModelType = 1 | 2;

export class PricingModel {
  calculation: PricingModelType;
  public supplierName: string = tlang`%%supplier%%`;

  constructor(calculation: PricingModelType, min?: number, max?: number) {
    this.calculation = calculation;
    this._min = min;
    this._max = max;
  }

  _min?: number | null;

  get min() {
    return this._min ?? 0;
  }

  _max?: number | null;

  get max() {
    switch (this.calculation) {
      case pricingModelType.margin:
        return this._max ?? 99;
      case pricingModelType.markup:
        return this._max ?? -1;
      default:
        throw new DevelopmentError(`Unknown pricing model type: ${this.calculation}`);
    }
  }

  get editorMin() {
    return this._min ?? 0;
  }

  get editorMax() {
    switch (this.calculation) {
      case pricingModelType.margin:
        return this._max ?? 99;
      case pricingModelType.markup:
        return (this._max ?? -1) === -1 ? undefined : (this._max ?? undefined);
    }
    return undefined;
  }

  get label() {
    return PricingModel.getPricingModelLabel(this.calculation, this.min, this.max);
  }

  public static getCalculationTypeLabel(calculation: number) {
    switch (calculation) {
      case pricingModelType.margin:
        return tlang`Margin`;
      case pricingModelType.markup:
        return tlang`Markup`;
      default:
        return tlang`Unknown`;
    }
  }

  public static getPricingModelLabel(calculation: PricingModelType, min?: number, max?: number) {
    const calcType = PricingModel.getCalculationTypeLabel(calculation);

    switch (calculation) {
      case pricingModelType.markup:
        if (isNothing(min, max) || (min === 0 && max === -1)) return `${calcType} (%)`;
        if (isSomething(min) && (min ?? 0) > 0 && (isNothing(max) || max === -1)) return `${calcType} (${min ?? 0}% <)`;
        return `${calcType} (${min ?? 0}% - ${max ?? 2000}%)`;
      case pricingModelType.margin:
        return min || max ? `${calcType} (${min ?? 0}% - ${max ?? 99}%)` : `${calcType} (%)`;
      default:
        throw new DevelopmentError(`Unknown pricing model type: ${calculation}`);
    }
  }

  update(o: { calculation?: PricingModelType; min?: number | null; max?: number | null }) {
    this.calculation = o.calculation ?? this.calculation;
    this._min = o.min ?? null;
    this._max = o.max ?? null;
  }

  getRangeValidations(errors?: IErrorList): IErrorList {
    if (!errors) errors = errorList();
    switch (this.calculation) {
      case pricingModelType.margin:
        errors.assert(this.min >= 0, tlang`Minimum Margin value cannot be less than 0%`);
        errors.assert(this.min < this.max, tlang`Maximum Margin value cannot be less that or equal to Minimum Margin`);
        errors.assert(this.max < 100, tlang`Maximum Margin value must be less than 100%`);
        break;
      case pricingModelType.markup:
        errors.assert(this.min >= 0, tlang`Minimum Markup value cannot be less than 0%`);
        errors.assert(
          this.max === -1 || this.max >= this.min,
          tlang`Maximum Markup value cannot be less than Minimum Markup `
        );
        break;
      default:
        throw new DevelopmentError(`Unknown pricing model type: ${this.calculation}`);
    }
    return errors;
  }

  getValueValidations(value: number, errors?: IErrorList): IErrorList {
    if (!errors) errors = errorList();
    const subRange = this.max === -1 ? tlang`${this.min}%` : tlang`${this.min}%-${this.max}%`;

    switch (this.calculation) {
      case pricingModelType.margin:
        errors.assert(
          value >= this.min && value <= this.max,
          tlang`${'ref:WI218755-supplier-margin-restriction-msg'}${this.supplierName} has restricted the value between ${subRange}, please set a valid value.`
        );
        break;
      case pricingModelType.markup:
        errors.assert(
          value >= this.min && (this.max === -1 || value <= this.max),
          tlang`${'ref:WI218755-supplier-markup-restriction-msg'}${this.supplierName} has restricted the value from ${subRange}, please set a valid value.`
        );
        break;
      default:
        throw new DevelopmentError(`Unknown pricing model type: ${this.calculation}`);
    }
    return errors;
  }
}
