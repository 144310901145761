// eslint-disable-next-line import/named
import { AskConfirmation, buttonsYesNo } from '../../../../webmodule-common/other/ui/modal-confirmation';
import { cache } from '../../cache-impl/cache-registry';
import { checkValidations } from '../../../../webmodule-common/other/ui/data-entry-screen-helpers';
import { clientContainerManagerFactory } from '../../clients/client-ui-adaptor';
import { ClientPicker } from '../../../clients/views/client-picker';
import { clone } from '../../../../webmodule-common/other/clone';
import { constructAsync } from '../../../../webmodule-common/other/async-constructor';
import { ContactPicker } from '../../../clients/views/contact-picker';
import { createNewClient } from '../../clients/data/franchisee-client-container';
import { customElement, state } from 'lit/decorators.js';
import { DevelopmentError, showDevelopmentError } from '../../../../webmodule-common/other/development-error';
import {
  emptyAddress,
  isAddressNullOrEmpty,
  singleLineAddress
} from '../../../../webmodule-common/other/ui/maps/map-helpers';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker';
import { fireQuickWarningToast } from '../../../../webmodule-common/other/toast-away';
import type { PickerResult } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { FranchiseeClientView } from '../../clients/views/franchisee-client-view';
import { FranchiseePaymentProfilePicker } from '../../franchisee/view/franchisee-payment-profile-picker';
import {
  FranchiseeQuoteAddressManager,
  FranchiseeQuoteAddressManagerType
} from '../data/franchisee-quote-address-manager';
import { FranchiseeQuoteContainer } from '../data/franchisee-quote-container';
import { getApiFactory } from '../../../api/api-injector';
import { getBranchUserCache } from '../../cache-impl/dealer-branch-user-cache';
import { getInternalId } from '../../../../webmodule-common/other/ui/databinding/databinding';
import { getQuoteSupplierDisplayName } from '../../../quotes/data/quoteSupplierProvider';

import { getToolTip } from '../../../../webmodule-common/other/tooltip';
import { html } from 'lit';
import { isEmptyOrSpace, validId } from '../../../../webmodule-common/other/ui/string-helper-functions';
import { lockUIandExecute } from '../../../../webmodule-common/other/ui-lock';
import type { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { mapPaymentProfileCalculation } from '../data/franchisee-quote-manager';
import type { QuoteDetailViewOptions } from '../../../quotes/views/quote-detail-view';
import { QuoteDetailView } from '../../../quotes/views/quote-detail-view';
import { QuoteState, QuoteType } from '../../../api/dealer-api-interface-quote';
import { serverDateTimeToLocalRFC3339 } from '../../../../webmodule-common/other/datetime-converter';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../common/current-user-data-store';
import type { AddressEditor, AddressOption } from '../../../../webmodule-common/other/ui/address-editor';
import type { BranchQuote, ResultGetSupplierQuoteSettings } from '../../../api/dealer-api-interface-franchisee';
import type { Client } from '../../../api/dealer-api-interface-client';
import type { DataCache } from '../../../../webmodule-common/cache/data-cache';
import type { DataCacheGeneric } from '../../../../webmodule-common/cache/generic-data-cache';
import type { EventTemplate } from '../../../../webmodule-common/other/ui/events';
import type { PaymentProfileApi } from '../../../api/payment-profile-api';
import type { PaymentProfileCacheData } from '../../cache-impl/cache-data';
import type { SupplierApi } from '../../../api/supplier-api';
import type { TemplateResult } from 'lit-html';
import type { TenantLoginPublicInfo } from '../../../api/dealer-api-interface-user';
import type {
  WebmoduleChangeEvent,
  WebmoduleRadioGroup
} from '../../../../webmodule-common/components/src/webmodule-components';
import { PricingModel } from '../../../../webmodule-common/pricing/pricing-model';
import { FormInputSelectValue } from '../../../../webmodule-common/other/FormInputView';
import { quoteTypeToString } from '../../../quotes/data/quote-helper-functions';

@customElement('wm-franchiseequotedetailview')
export class FranchiseeQuoteDetailView extends QuoteDetailView {
  contactCache: DataCacheGeneric = cache().contact;
  clientCache = cache().client;
  primaryContactCache = cache().primaryContact;
  paymentProfileCache: DataCache<PaymentProfileCacheData> = cache().paymentProfile;
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  supplierApi: SupplierApi = getApiFactory().supplier();
  internalId: string;
  clientPickerDisplay = '';
  contactPickerDisplay = '';
  clientTypePickerDisplay = '';
  branchUserCache = getBranchUserCache();
  supplierQuoteConfig?: ResultGetSupplierQuoteSettings;
  addressManager: FranchiseeQuoteAddressManager;

  private updatePricingMargin?: boolean;
  @state()
  private _supplierDisplayName: string = tlang`%%supplier%%`;

  constructor(options: QuoteDetailViewOptions) {
    super(options);
    this.internalId = getInternalId();

    this.addressManager = new FranchiseeQuoteAddressManager(this.quoteManager as FranchiseeQuoteContainerManager);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.branchQuote),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('clientId');
    addField('contactId');
    addField('clientTypeId');
    addField('assignedToUserId', FieldType.string, false, undefined, () => this.quote);
    this.dataTracker.addDynamic(
      'addressSource',
      FieldType.string,
      () =>
        this.quote.shippingAddress?.source ??
        this.addressManager.getAddressTagByType(FranchiseeQuoteAddressManagerType.other),
      (value: string) => {
        this.setAddressByType(this.addressManager.getAddressTypeByTag(value));
        this.requestUpdate();
      }
    );
  }

  get validityPeriod(): number | undefined {
    return this.supplierQuoteConfig?.validityPeriod;
  }

  get leadTime(): number | null {
    return this.supplierQuoteConfig?.leadPeriod ?? null;
  }

  protected get branchQuote(): BranchQuote | null {
    if (!(this.quoteManager.container instanceof FranchiseeQuoteContainer))
      throw new DevelopmentError('FranchiseeQuoteDetailView requires a container type FranchiseeQuoteContainer');
    return this.quoteManager.container.branchQuote;
  }

  async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    await this.branchUserCache.prefetch();
    await this.quoteManager.needsQuote();
    await this.branchUserCache.addUsersToBranch(this.branchQuote!.branchId, [
      this.quote.assignedToUserId,
      this.quote.creationUserId,
      this.quote.lastModifiedUserId
    ]);
    this._supplierDisplayName = await getQuoteSupplierDisplayName(this.quote.supplierId);
  }

  public async prepareForSave(): Promise<void> {
    if (!this.branchQuote) {
      await showDevelopmentError(`BranchQuote is null`);
      return;
    }
    const oldClientTypeId = this.branchQuote.clientTypeId;
    const oldQuoteType = this.quote.quoteType;

    this.dataTracker.applyChangeToValue();

    this.quote.quoteCustomerId = this.branchQuote.clientId ?? emptyGuid;

    //If there was no change in client type or quote type, we can exit. Otherwise update terms and margin if applicable.
    if (oldClientTypeId == this.branchQuote.clientTypeId && oldQuoteType == this.quote.quoteType) return;

    const paymentProfile = await this.paymentProfileCache.get(this.branchQuote?.clientTypeId);

    if (!isEmptyOrSpace(this.branchQuote.clientTypeId)) {
      if (!paymentProfile?.data) {
        await showDevelopmentError(`PaymentProfile is null. cache entry "${this.branchQuote.clientTypeId}"`);
        return;
      }

      const profile = paymentProfile.data;

      if (this.updatePricingMargin == true) {
        if (profile.paymentProfile.supplierId === this.quote.supplierId) {
          //Update Margins when client type changed.
          if (oldClientTypeId !== this.branchQuote.clientTypeId) {
            const price = this.quoteManager.quotePrice;
            price.percentMarginOrMarkup = profile.paymentProfile.value;
            price.calculation = mapPaymentProfileCalculation(profile.paymentProfile.calculation);
          }
        }

        //Update Terms
        if (this.quote.quoteType == QuoteType.Quote)
          this.quoteManager.quote.termsAndConditions = profile.paymentProfile.quoteTerms;
        else if (this.quote.quoteType == QuoteType.Estimate)
          this.quoteManager.quote.termsAndConditions = profile.paymentProfile.estimateTerms;
        else this.quoteManager.quote.termsAndConditions = null;

        this.updatePricingMargin = undefined;
      }
    }
  }

  async loadOrRefresh(): Promise<void> {
    await super.loadOrRefresh();
    //need quote items to check the client type state
    await this.loadPickerDisplaysFromCache();

    if (!this.supplierQuoteConfig) {
      const _supplierQuoteConfig = await this.supplierApi.getSupplierQuoteConfig({
        supplierId: this.quote.supplierId
      });
      if (_supplierQuoteConfig) {
        this.supplierQuoteConfig = _supplierQuoteConfig;
      }
    }

    await this.addressManager.loadAddresses();
    const addressChanged = this.addressManager.updateCurrentQuoteAddress();
    if (addressChanged) {
      this.showCheckShippingNoteWarning();
    }
    this.requestUpdate();
  }

  getValidationErrorsForState(state: QuoteState): string[] {
    const errors = super.getValidationErrorsForState(state);

    if (!this.branchQuote) {
      throw new DevelopmentError('BranchQuote is null');
    }

    if (state == QuoteState.Active) {
      const clientId = this.branchQuote.clientId;
      const contactId = this.branchQuote.contactId;

      if (isEmptyOrSpace(clientId)) errors.push(tlang`Please select a %%client%%`);

      if (isEmptyOrSpace(contactId)) errors.push(tlang`Please select a %%contact%%`);
    }

    if (state == QuoteState.Cancelled) {
      return [];
    }

    if (state == QuoteState.IssuePending) {
      if (isAddressNullOrEmpty(this.quote.shippingAddress)) errors.push(tlang`Please select or enter a valid address`);
    }

    const clientTypeId = this.branchQuote.clientTypeId;

    if (isEmptyOrSpace(clientTypeId)) errors.push(tlang`Please select a %%payment-profile%% for accurate pricing`);

    return errors;
  }

  async createNewClient() {
    const title = tlang`Add %%client%%`;
    const container = await createNewClient(userDataStore.clientOwnerId);
    if (!container) {
      await showDevelopmentError('Client could not be created');
      return;
    }

    const qm = clientContainerManagerFactory(container);

    const qv = await constructAsync(
      new FranchiseeClientView({
        viewTitle: () => title,
        clientContainerManager: qm
      })
    );
    await qv.showModal();
    const client = qv.view?.clientContainer.client;
    if (client && validId(client.id)) {
      this.selectClient(client);
    }
  }

  addressModified(addressEditor: AddressEditor) {
    if (addressEditor.address) {
      this.quote.shippingAddress = clone(addressEditor.address);
      this.quote.shippingNotes = addressEditor.shippingNotes ?? '';
    } else {
      this.quote.shippingAddress = emptyAddress();
      this.quote.shippingNotes = '';
    }
  }

  async valid(state: QuoteState): Promise<boolean> {
    const errors = this.getValidationErrorsForState(state);
    return await checkValidations(errors);
  }

  protected template(): EventTemplate {
    if (!this.loggedIn) return html``;
    const allowChangeClient = !this.quoteManager.isReadonly() && this.quoteManager.quoteState == QuoteState.Draft;
    const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());

    const createClientEvent = async () => lockUIandExecute(async () => await this.createNewClient());
    const eventPickClient = async (): Promise<PickerResult | undefined> => {
      await this.selectClient();
      return undefined;
    };
    const createClientTemplate: TemplateResult | undefined = allowChangeClient
      ? forms.buttonPrimary(tlang`New`, {
          events: { click: createClientEvent },
          slot: 'suffix',
          class: 'quote-client-picker-button'
        })
      : undefined;

    const clientPicker = allowChangeClient
      ? forms.picker('clientId', this.clientPickerDisplay, eventPickClient, tlang`%%client%%`, createClientTemplate)
      : forms.pickerReadonly(
          'clientId',
          this.clientPickerDisplay,

          tlang`%%client%%`
        );
    const eventPickContact = async (): Promise<PickerResult | undefined> => {
      await this.selectContact();
      return undefined;
    };

    const contactPicker = forms.picker('contactId', this.contactPickerDisplay, eventPickContact, tlang`%%contact%%`);

    const eventPickClientType = async (): Promise<PickerResult | undefined> => {
      await this.selectClientType();
      return undefined;
    };

    const clientTypePicker = forms.picker(
      'clientTypeId',
      this.clientTypePickerDisplay,
      eventPickClientType,
      tlang`%%payment-profile%%`
    );

    const validUntilToolTipText = tlang`The system calculates the expiry date based on the default validity period
            (in ‘settings’) from the current date (e.g. today + 28 days), every day until the date you actually issue the %%quote%%.`;

    const installationDateToolTipText = tlang`Ensure that you consider the supplier’s scheduling, ordering, fabrication & shipping lead
            times before committing to a proposed delivery date to the client. If in doubt, contact the supplier for confirmation.`;

    const validityDisplay = () => {
      let valDisplay = !this.validityPeriod ? '' : `${this.validityPeriod} day${this.validityPeriod == 1 ? '' : 's'}`;
      let valLabel = tlang`Validity Period`;
      let controlType = 'text';
      let controlToolTip: string | null = validUntilToolTipText;

      if (this.quote.validUntilDate) {
        controlType = 'datetime-local';
        valLabel = tlang`'Valid To' Date`;
        valDisplay = serverDateTimeToLocalRFC3339(this.quote.validUntilDate);
        controlToolTip = null;
      }

      return html`
        <webmodule-input
          class="webmodule-control-left-label"
          id=${'quote-validityDisplay-' + this.dataTracker.binder.internalId}
          type=${controlType}
          filled=${true}
          readonly=${true}
          value=${valDisplay}
          size="small"
        >
          <div slot="label">${valLabel}:${controlToolTip ? getToolTip(controlToolTip) : ''}</div>
        </webmodule-input>
      `;
    };
    const addressTemplate1 = this.getAddressEditorTemplate(forms);

    const dealerName = userDataStore.franchisee.name;
    const dealerOrderNo = isEmptyOrSpace(dealerName) ? tlang`%%franchisee%% Order No.` : tlang`${dealerName} Order No.`;

    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0);
    minDate.setDate(minDate.getDate() + (this.leadTime ?? 0) * 7);

    const users = this.branchUserCache.getBranchUsers(this.branchQuote?.branchId ?? emptyGuid);
    const userToOption = (u: TenantLoginPublicInfo) => ({ text: u.friendlyName ?? '', value: u.id, disabled: false });

    let typesToHide: number[] | undefined = undefined;

    if (this.supplierQuoteConfig?.availableQuoteTypes)
      typesToHide = Object.values(QuoteType)
        .filter(value => !isNaN(Number(value)))
        .map(Number)
        .filter(x => !this.supplierQuoteConfig?.availableQuoteTypes.includes(x));

    return html`
      <form class="py-3 px-0 frm-quote-details form-two-col" novalidate>
        <div class="row">
          <div>
            ${forms.text('title', tlang`Title`, 200)} ${clientPicker} ${contactPicker} ${clientTypePicker}
            ${forms.money('budget', tlang`Budget`)} ${forms.note('description', tlang`Description`, 2950)}
          </div>
          <div>
            ${forms.text('customQuoteNumber', dealerOrderNo, 200)}
            ${forms.radioGroupHideValues('quoteType', QuoteType, tlang`Type`, typesToHide, quoteTypeToString)}
            ${forms.arraySelect('assignedToUserId', users, userToOption, { title: tlang`Author` })}
            ${forms.dateReadonly('dateCreated', tlang`Created`)}
            ${forms.dateReadonly('lastModifiedDate', tlang`Last Modified`)} ${validityDisplay()}
            ${forms.date(
              'installationDate',
              tlang`Proposed Delivery`,
              'date',
              installationDateToolTipText,
              minDate.getTime()
            )}
          </div>
        </div>
        ${addressTemplate1}
      </form>
    `;
  }

  protected setAddressByType(type: FranchiseeQuoteAddressManagerType) {
    this.addressManager.updateAddress(type);
  }

  protected async setDeliveryAddress(e: WebmoduleChangeEvent) {
    const target = e.target as WebmoduleRadioGroup;
    this.setAddressByType(this.addressManager.getAddressTypeByTag(target.value));
    this.requestUpdate();
  }

  protected showCheckShippingNoteWarning() {
    fireQuickWarningToast(tlang`The currently selected delivery address has been updated.
      Please review the !!shipping-note!! to ensure that they are still valid.`);
  }

  private getAddressEditorTemplate(forms: FormInputAssistant) {
    const shippingOptionEntry = (
      type: FranchiseeQuoteAddressManagerType,
      allowDisabled = true
    ): FormInputSelectValue => {
      return {
        value: this.addressManager.getAddressTagByType(type) ?? '',
        text: `${this.addressManager.getAddressLabelByTag(this.addressManager.getAddressTagByType(type))}
                    ${tlang`${'ref:addressLabel'}Address`}`,
        disabled:
          (allowDisabled && isAddressNullOrEmpty(this.addressManager.addressByType(type))) ||
          this.quoteManager.isReadonly()
      };
    };

    const shippingOptions = () => {
      const addOptions = [
        shippingOptionEntry(FranchiseeQuoteAddressManagerType.branch),
        shippingOptionEntry(FranchiseeQuoteAddressManagerType.client),
        shippingOptionEntry(FranchiseeQuoteAddressManagerType.project),
        shippingOptionEntry(FranchiseeQuoteAddressManagerType.other, false)
      ];

      const disableWillCall =
        !(this.supplierQuoteConfig?.willCallLocations && this.supplierQuoteConfig.willCallLocations.length > 0) ?? true;

      addOptions.push({
        value: this.addressManager.getAddressTagByType(FranchiseeQuoteAddressManagerType.willCall) ?? '',
        text: `${this.addressManager.getAddressLabelByTag(this.addressManager.getAddressTagByType(FranchiseeQuoteAddressManagerType.willCall))}
                    ${tlang`${'ref:addressLabel'}Address`}`,
        disabled: disableWillCall || this.quoteManager.isReadonly()
      });

      return addOptions;
    };

    const opts = shippingOptions();

    const wasImmediate = forms.makeImmediate;
    try {
      const addrSelector = forms.radioGroupArray('addressSource', opts, {
        title: tlang`Delivery Address`,
        slot: 'subtitle'
      });

      const addressPickerList =
        !this.quoteManager.isReadonly() &&
        this.quote.shippingAddress?.source &&
        this.quote.shippingAddress?.source === 'franchisee-address-type:willcall' &&
        this.supplierQuoteConfig?.willCallLocations
          ? this.supplierQuoteConfig.willCallLocations.map(
              x =>
                ({
                  ...x.willCallAddress,
                  display: `${x.title} - ${x.type} - ${singleLineAddress(x.willCallAddress)}`
                }) as AddressOption
            )
          : null;

      return html` <wm-addresseditor
        .subheader=${addrSelector}
        .preSelectAddressList="${addressPickerList}"
        @wm-ae-changed=${(e: Event) => this.addressModified(e.currentTarget as AddressEditor)}
        id="physicalAddressEditor"
        .address=${this.quote.shippingAddress}
        .shippingNotes=${this.quote.shippingNotes}
        .title=${tlang`Deliver from ${this._supplierDisplayName} to`}
        .readonly=${this.quoteManager.isReadonly() ||
        this.quote.shippingAddress?.source !== 'franchisee-address-type:other'}
        .shippingNotesReadonly=${this.quoteManager.isReadonly()}
        .isDefaultShippingVisible=${false}
      >
      </wm-addresseditor>`;
    } finally {
      forms.immediateBindingUpdate = wasImmediate;
    }
  }

  private async loadPickerDisplaysFromCache() {
    if (!this.branchQuote) {
      this.clientPickerDisplay = '';
      this.contactPickerDisplay = '';
      this.clientTypePickerDisplay = '';
    } else {
      const clientData = await this.clientCache.get(this.branchQuote.clientId);
      this.clientPickerDisplay = clientData?.displayValue ?? '';

      this.contactPickerDisplay = (await this.contactCache.get(this.branchQuote.contactId))?.displayValue ?? '';
      this.clientTypePickerDisplay =
        (await this.paymentProfileCache.get(this.branchQuote.clientTypeId))?.displayValue ?? '';
    }
  }

  private async selectClient(client?: Client) {
    const selectedClient = client?.id ?? (await ClientPicker())?.id;

    if (!selectedClient) {
      return;
    }
    const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());

    const clientCacheItem = await this.clientCache.get(selectedClient);
    const clientData = clientCacheItem?.data;
    if (!clientData) return;
    this.clientPickerDisplay = clientCacheItem?.displayValue ?? '';

    forms.updatePicker('clientId', { id: selectedClient, value: this.clientPickerDisplay });

    const franchiseeClient = clientData.franchiseeClient!;

    const primaryContact = await this.primaryContactCache.getData(selectedClient);
    const contact = await this.contactCache.get(primaryContact?.contactId ?? emptyGuid);
    this.contactPickerDisplay = contact?.displayValue ?? '';

    forms.updatePicker('contactId', { id: primaryContact?.contactId ?? emptyGuid, value: this.contactPickerDisplay });

    const defaultPaymentProfile = await this.paymentProfileCache.get(franchiseeClient.paymentProfileId);

    if (defaultPaymentProfile && defaultPaymentProfile.id !== this.dataBinding.getValue('clientTypeId')) {
      await this.updateClientTypeAndPickerDisplay(defaultPaymentProfile.id);
    }

    this.updateClientAddress(clientData.client);

    this.requestUpdate();
  }

  private async selectContact() {
    const selectedClient = this.dataBinding.getValue('clientId');

    const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());
    if (selectedClient === emptyGuid) {
      this.contactPickerDisplay = '';
      forms.updatePicker('contactId', { id: emptyGuid, value: this.contactPickerDisplay });
      fireQuickWarningToast(tlang`Please select a %%client%% first.`, 1000);
    } else {
      const selectedContact = await ContactPicker(selectedClient);

      if (selectedContact) {
        this.contactPickerDisplay = selectedContact.name ?? '';
        forms.updatePicker('contactId', { id: selectedContact.id, value: this.contactPickerDisplay });
      }
    }
    this.requestUpdate();
  }

  private async selectClientType() {
    const selectedClientType = await FranchiseePaymentProfilePicker(this.paymentProfileApi, this.quote.supplierId);

    if (selectedClientType) {
      await this.updateClientTypeAndPickerDisplay(selectedClientType.id);
      this.requestUpdate();
    }
  }

  private async updateClientTypeAndPickerDisplay(id: string) {
    const paymentProfile = await this.paymentProfileCache.get(id);

    if (paymentProfile && paymentProfile.id !== this.dataBinding.getValue('clientTypeId')) {
      const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());
      const forceUpdateCalculation =
        this.quoteManager.quotePrice.calculation !==
        mapPaymentProfileCalculation(paymentProfile.data.paymentProfile.calculation);

      this.clientTypePickerDisplay = paymentProfile.displayValue;
      forms.updatePicker('clientTypeId', { id: paymentProfile.id, value: this.clientTypePickerDisplay });

      //TODO: If switching between calculation types we have to update
      const currentCalculation = PricingModel.getPricingModelLabel(this.quoteManager.quotePrice.calculation);
      const newCalculation = PricingModel.getPricingModelLabel(
        mapPaymentProfileCalculation(paymentProfile.data.paymentProfile.calculation)
      );

      if (
        this.branchQuote?.clientTypeId !== emptyGuid &&
        (forceUpdateCalculation ||
          (await AskConfirmation(
            tlang`Would you like to change the pricing model from ${currentCalculation} to ${newCalculation} and the T&C’s on the Quote Summary page? This will alter the Quote Price.`,
            buttonsYesNo(),
            undefined,
            'Update Pricing Model and Terms & Conditions'
          )))
      ) {
        this.updatePricingMargin = true;

        const msg = forceUpdateCalculation
          ? tlang`Due to an update to the pricing model associated with the ‘%%payment-profile%%’, the quote inherited the Pricing Metric and Terms & Conditions.`
          : tlang`The ‘%%payment-profile%%’ change will inherit its’ associated Margin and Terms & Conditions.`;

        fireQuickWarningToast(`${msg} ${tlang`Please review details on the !!quote-item!! tab.`}`);
      } else if (this.branchQuote?.clientTypeId === emptyGuid) {
        this.updatePricingMargin = true;
      }
    }
  }

  private updateClientAddress(client: Client) {
    const existingAddressType = this.addressManager.getAddressTypeByTag(this.quote.shippingAddress?.source);
    this.addressManager.setClient(client);

    const isClientAddressEmpty = isAddressNullOrEmpty(client.physicalAddress);

    if (
      !isClientAddressEmpty &&
      client.shipToPhysicalAddress &&
      existingAddressType !== FranchiseeQuoteAddressManagerType.project
    ) {
      this.setAddressByType(FranchiseeQuoteAddressManagerType.client);
      if (existingAddressType !== FranchiseeQuoteAddressManagerType.other) {
        this.showCheckShippingNoteWarning();
      }
    }
  }
}
