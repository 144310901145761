// eslint-disable-next-line import/named
import { asMarkdownTemplate } from '../../../webmodule-common/other/general/markdown';
import { customElement } from 'lit/decorators.js';
import { EventTemplate } from '../../../webmodule-common/other/ui/events';
import { html } from 'lit';
import { PageView } from './base-page-view';
import { tlang } from '../../../webmodule-common/other/language/lang';

@customElement('wm-franchiseestatusview')
export class FranchiseeStatusView extends PageView {
  constructor() {
    super();
  }

  protected template(): EventTemplate {
    let refCounter = 0;
    const ref = () => `ref:CompanyInfoSettingsForm:${refCounter++}`;

    const markdownText = tlang`${'ref:CompanyInfoSettingsForm:markdown'}

**%%estimate%%/%%quote%% - Draft**<br>
%%estimate%%/%%quote%%  will remain in **DRAFT** status until changed by the user to **ACTIVE**. There
                        are four mandatory* requirements to move to **ACTIVE**.<br>
In **DRAFT**, the %%estimate%%/%%quote%% can be edited, saved, duplicated, printed (with a **DRAFT**
                        watermark) or **CANCELLED** but it cannot be **ISSUED**.<br>
When a **DRAFT** %%estimate%%/%%quote%% is changed to **ACTIVE**, a new %%project%% is automatically
                                generated, the user will be redirected to this page.<br>
An %%estimate%%/%%quote%% can also be created from within an existing %%project%%. When
                                created inside a %%project%% they will be **ACTIVE** by default not **DRAFT**.<br>
<dt><strong>Status Name</strong></dt>
<dd><strong>Status Description</strong></dd>
        `;

    return html`
        <div>
            <form id="StatusSettingsForm" class="form-two-col">
                <div class="row">
                    <div>
                        <h2 class="section-header">${tlang`${ref()}Definition of all Statuses in the system`}</h2>
                        <dl>
                            ${asMarkdownTemplate(markdownText)}
                            <dt>${tlang`${ref()}Est/Qte - Active`}</dt>
                            <dd>${tlang`${ref()}Est/Qte will remain in ACTIVE status until ISSUED or CANCELLED.`}</dd>
                            <dt></dt>
                            <dd>${tlang`${ref()}In ACTIVE, the Est/Qte can be edited, saved, duplicated, cancelled or printed
                                (with DRAFT watermark) - it can also be ISSUED.`}</dd>
                            <dt>${tlang`${ref()}Est/Qte - Issue Pending`}</dt>
                            <dd>${tlang`${ref()}Est/Qte stays in Issue Pending status until the system has successfully
                                processed it.`}</dd>
                            <dt>${tlang`${ref()}Est/Qte - Issued`}</dt>
                            <dd>${tlang`${ref()}Once ISSUED the Est/Qte it is no longer editable - it is locked.`}</dd>
                            <dt></dt>
                            <dd>${tlang`${ref()}Est/Qte will remain in ISSUED state until ACCEPTED, REJECTED, LAPSED OR
                                CANCELLED.`}</dd>
                            <dt>${tlang`${ref()}Est/Qte - Accepted`}</dt>
                            <dd>${tlang`${ref()}If ACCEPTED the Est/Qte will be archived. A DRAFT Purchase Order will be
                                automatically generated.`}</dd>
                            <dt></dt>
                            <dd>${tlang`${ref()}User will be redirected to the Project/Financials page where this is
                                available.`}</dd>
                            <dt>${tlang`${ref()}Est/Qte - Rejected`}</dt>
                            <dd>${tlang`${ref()}If REJECTED the Est/Qte will be archived. It may be duplicated if a new version
                                is required.`}</dd>
                            <dt>${tlang`${ref()}Est/Qte - Lapsed`}</dt>
                            <dd>${tlang`${ref()}If LAPSED the Est/Qte will be archived. It may be duplicated if it needs to be
                                reactivated and repriced.`}</dd>
                            <dt>${tlang`${ref()}Est/Qte - Cancelled`}</dt>
                            <dd>${tlang`${ref()}If CANCELLED the Est/Qte will be archived. It may be duplicated if a new version
                                is required.`}</dd>
        
                            <dt></dt>
                            <dd></dd>
                            <dt>${tlang`${ref()}Project - Active`}</dt>
                            <dd>${tlang`${ref()}Project should remain in an ACTIVE state until completion.`}</dd>
                            <dt>${tlang`${ref()}Project - Cancelled`}</dt>
                            <dd>${tlang`${ref()}Project can be CANCELLED at any time unless there are unreconciled Purchase
                                Orders. These will display as CANCELLED in the ARCHIVED list.`}</dd>
                            <dt>${tlang`${ref()}Project - Archived`}</dt>
                            <dd>${tlang`${ref()}Project can be ARCHIVED only when all Purchase Orders are reconciled.`}</dd>
        
                            <dt></dt>
                            <dd></dd>
                            <dt>${tlang`${ref()}Purchase Order - Draft`}</dt>
                            <dd>${tlang`${ref()}An ACCEPTED Est/Qte will automatically generate a DRAFT Purchase Order inside a
                                project.`}</dd>
                            <dt></dt>
                            <dd>${tlang`${ref()}Purchase Order must be assigned to a Project. It can be generated from an
                                Estimate, Quote, Project or the Purchase Order page.`}</dd>
                            <dt>${tlang`${ref()}Purchase Order - Issue Pending`}</dt>
                            <dd>${tlang`${ref()}Purchase Order stays in Issue Pending status until the system has successfully
                                processed it.`}</dd>
                            <dt>${tlang`${ref()}Purchase Order - Issued`}</dt>
                            <dd>${tlang`${ref()}Purchase Order once sent to the supplier is labelled ISSUED, it is then locked
                                and archived.`}</dd>
                            <dt>${tlang`${ref()}Purchase Order - Cancelled`}</dt>
                            <dd>${tlang`${ref()}Purchase Order can be cancelled in any state. The state will change to CANCELLED
                                and will be archived - not deleted.`}</dd>
                            <dt>${tlang`${ref()}Purchase Order - Completed`}</dt>
                            <dd>${tlang`${ref()}ISSUED Purchase Order must be reconciled (state changed to COMPLETED) before the
                                project can be ARCHIVED.`}</dd>
        
                            <dt></dt>
                            <dd></dd>
                            <dt>${tlang`${ref()}Invoice - Draft`}</dt>
                            <dd></dd>
                            <dt>${tlang`${ref()}Invoice - Issued`}</dt>
                            <dd></dd>
                            <dt>${tlang`${ref()}Invoice - Cancelled`}</dt>
                            <dd></dd>
                            <dt>${tlang`${ref()}Invoice - Credit`}</dt>
                            <dd></dd>
                            <dt>${tlang`${ref()}Invoice - Reconciled`}</dt>
                            <dd></dd>
                        </dl>
                    </div>
                    <div>
                        <h2 class="section-header">&nbsp;</h2>
        
                        <h5>${tlang`${ref()}Definition of a State`}</h5>
                        <p>
                            ${tlang`${ref()}There are only two states: Active or Archived.`}<br />
                            ${tlang`${ref()}This defines where a user might search to find an item, is it current or old? List
                            tables are segmented by these states.`}
                        </p>
                        <h5>${tlang`${ref()}Definition of a Status`}</h5>
                        <p>
                            ${tlang`${ref()}Each status resides in an ‘Active’ or ‘Archived’ State depending on whether it is
                            current or not. See color coded panels below.`}
                        </p>
                        <div class="settings-status-definition-labels">
                            <div class="states-list active-states">
                                <div class="state-header">${tlang`${ref()}Active State`}</div>
                                <div class="state-draft">${tlang`${ref()}Draft`}</div>
                                <div class="state-active">${tlang`${ref()}Active`}</div>
                                <div class="state-review-pending">${tlang`${ref()}Review Pending`}</div>
                            </div>
                            <div class="states-list archived-states">
                                <div class="state-header">${tlang`${ref()}Archived State`}</div>
                                <div class="state-issuepending">${tlang`${ref()}Issue Pending`}</div>
                                <div class="state-issued">${tlang`${ref()}Issued`}</div>
                                <div class="state-reviewed">${tlang`${ref()}Reviewed`}</div>
                                <div class="state-accepted">${tlang`${ref()}Accepted`}</div>
                                <div class="state-approved">${tlang`${ref()}Approved`}</div>
                                <div class="state-lapsed">${tlang`${ref()}Lapsed`}</div>
                                <div class="state-rejected">${tlang`${ref()}Rejected`}</div>
                                <div class="state-cancelled">${tlang`${ref()}Cancelled`}</div>
                                <div class="state-completed">${tlang`${ref()}Completed`}</div>
                                <div class="state-reconcilled">${tlang`${ref()}Reconciled`}</div>
                            </div>
                        </div>
                        <p>${tlang`${ref()}Projects, quotes, purchase orders, invoices have various statuses which define where
                            they are at in the workflow.`}</p>
        
                        <h5>${tlang`${ref()}Definition of an Estimate`}</h5>
                        <p>
                            ${tlang`${ref()}An estimate is an educated guess at what a job may cost. It isn’t binding. To
                            account for possible unforeseen developments, you should provide several estimates based on various
                            circumstances, including the worst-case scenario. This will prevent your customer from being
                            surprised by the costs.`}
        
                        </p>
        
                        <h5>${tlang`${ref()}Definition of a Quote`}</h5>
                        <p>
                            ${tlang`${ref()}A quotation is a fixed price offer that can’t be changed once ACCEPTED by the
                            customer. You must adhere to the quotation price even if you carry out more work than you expected.
                            If you think this is likely to happen, it makes more sense to give an estimate. You can also specify
                            in the quotation precisely what it covers, and situations that will lead to additional charges.`}
        
                        </p>
        
                        <h5>${tlang`${ref()}Quote vs Estimate`}</h5>
                        <p>
                            ${tlang`${ref()}There is no difference in the system between a Quote and an Estimate, the only thing
                            that differentiates them is the legal definition (as described above).`}
                            ${tlang`${ref()}In the configuration settings there are separate text fields to describe the default
                            terms & conditions for each. These default terms & conditions are published on all quotes/estimates
                            in the footer. These Terms are editable so every quote/estimate can be customised if the user
                            chooses.`}<br />
                            ${tlang`${ref()}An estimate can be converted into a quote, if it has not yet been ACCEPTED by the
                            client.`}<br />
                            ${tlang`${ref()}A quote can be converted into an estimate, if it has not yet been ACCEPTED by the
                            client.`}<br />
                            ${tlang`${ref()}Once ACCEPTED – the quote or estimate can only be duplicated (to create another
                            version). The duplicate in DRAFT mode can be switched between [quote/estimate].`}<br />
                            ${tlang`${ref()}A REJECTED quote or estimate can be duplicated and also switched.`}
        
                        </p>
        
                        <h5>${tlang`${ref()}Projects`}</h5>
                        <p>
                            ${tlang`${ref()}A ‘Project’ contains all the relevant information for a project such as; quotes,
                            purchase orders, invoices, shipping, annotations, documents etc.`}<br />
                            ${tlang`${ref()}A DRAFT quote/estimate can be created without first creating a project. But before
                            it can be ISSUED to a client it must be associated with a project.`}<br />
                            ${tlang`${ref()}Changing a quote from DRAFT to ACTIVE state automatically prompts the user creates a
                            project.`}
                            ${tlang`${ref()}Alternatively a project can be created first and quotes created from within the
                            project.`}
                        </p>
        
                        <h5>${tlang`${ref()}Filtering of Lists`}</h5>
                        <p>
                            ${tlang`${ref()}All lists (tables) throughout web module can be filtered by STATE: All / Active /
                            Archived`}
                            <br />
                            ${tlang`${ref()}Such as:`}
                        </p>
                        <ul>
                            <li>${tlang`${ref()}Clients`}</li>
                            <li>${tlang`${ref()}Suppliers`}</li>
                            <li>${tlang`${ref()}Estimates`}</li>
                            <li>${tlang`${ref()}Quotes`}</li>
                            <li>${tlang`${ref()}Purchase Orders`}</li>
                            <li>${tlang`${ref()}Invoices`}</li>
                        </ul>
                        <p>
                            ${tlang`${ref()}Active is the default list. These are current
                            Projects, People & Documents.`}
                            <br />
                            ${tlang`${ref()}Archived are Rejected, Cancelled, Completed or
                            Inactive Projects, People & Documents.`}
                        </p>
        
                        <h5>
                            ${tlang`${ref()}Est/Qte (Archived List)`}
                        </h5>
                        <p>
                            <ul>
                                <li>${tlang`${ref()}Any Est/Qte that reside in a COMPLETED Project`}</li>
                                <li>${tlang`${ref()}Any Est/Qte that has been CANCELLED`}</li>
                                <li>${tlang`${ref()}Any Est/Qte that has been REJECTED (Declined)`}</li>
                                <li>${tlang`${ref()}Any Est/Qte that has LAPSED`}</li>
                            </ul>
                        </p>
        
                        <h5>${tlang`${ref()}Purchase Orders (Archived List)`}</h5>
                        <p>
                            <ul>
                                <li>${tlang`${ref()}Any Purchase Order that resides in a COMPLETED Project`}</li>
                                <li>${tlang`${ref()}Any Purchase Order that has been REJECTED (Declined)`}</li>
                                <li>${tlang`${ref()}Any Purchase Order that has LAPSED`}</li>
                            </ul>
                            ${tlang`${ref()}Note: Purchase Orders must be Reconciled first, before a Project can be ARCHIVED.`}
                        </p>
        
                        <h5>${tlang`${ref()}Invoices (Archived List)`}</h5>
                        <p>
                            <ul>
                                <li>${tlang`${ref()}Any Invoice that resides in a COMPLETED Project`}</li>
                                <li>${tlang`${ref()}Any Invoice that has been REJECTED (Declined)`}</li>
                                <li>${tlang`${ref()}Any Invoice that has LAPSED`}</li>
                            </ul>
                            ${tlang`${ref()}Note: Invoices must be Reconciled first, before a Project can be ARCHIVED.`}
                        </p>
                    </div>
                </div>
            </form>
        </div>`;
  }
}
