import { AppResourcePage } from './app-resource-base';
import { branchSwitchOnResource } from './helpers/branch-switch';
import { cache } from '../cache-impl/cache-registry';
import { checkSuppliersIsOnline } from '../quotes/quote-creator';
import { createQuoteView, getQuoteContainer, getQuoteContainerManager, QuoteLock } from '../quotes/quote-ui-adapter';
import { customElement } from 'lit/decorators.js';
import { FranchiseeQuoteContainerManager } from '../quotes/data/franchisee-quote-manager';
import { goStaticURL } from '../../../webmodule-common/other/ui/resource-resolver';
import { information } from '../../../webmodule-common/other/ui/modal-option';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { NullPromise } from '../../../webmodule-common/interop/types/misc';
import { ResourceLocker } from '../common/resource-lock';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { v6BootLoader } from '../../v6config/v6config';

@customElement('app-quote')
export class AppQuotePage extends AppResourcePage {
  qm?: FranchiseeQuoteContainerManager;
  async createModalEditor(id: string): NullPromise<ModalViewBase> {
    if (!(await v6BootLoader())) {
      await information(tlang`%%supplier%% service is OFFLINE. because of this, you will be restricted to readonly mode at this time. Please contact
        support if this problem is not resolved shortly.`);
      this.forcedReadonly = true;
      this.forcedReadonlyMsg = tlang`%%supplier%% OFFLINE`;
    }

    const container = getQuoteContainer(id);
    if (isEmptyOrSpace(id)) id = container.quoteId;

    this.qm = getQuoteContainerManager(container, this.lock) as FranchiseeQuoteContainerManager;
    const qm = this.qm;
    if (!(await qm.needsQuote())) return null;

    if (!(await branchSwitchOnResource(qm.branchQuote.branchId, tlang`%%quote%%`))) return null;
    if (!(await checkSuppliersIsOnline(this.qm.quote.supplierId))) {
      await information(tlang`%%supplier%% service is OFFLINE. because of this, you will be restricted to readonly mode at this time. Please contact
        support if this problem is not resolved shortly.`);
      this.forcedReadonly = true;
      this.forcedReadonlyMsg = tlang`%%supplier%% OFFLINE`;
    }
    this.qm.forceLockout = this.forcedReadonly;

    return await createQuoteView(qm);
  }
  protected async resourceRefreshing() {
    await super.resourceRefreshing();
    if (this.qm?.quote.id) {
      await cache().projectResourceLink.updateLocal(this.qm?.quote.id);
      await cache().quote.updateLocal(this.qm?.quote.id);
    }
  }
  async redirectPath(): NullPromise<string> {
    return '/quotes';
  }

  meta() {
    return {
      title: tlang`%%quote%% Editor`,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected createLock(id: string): ResourceLocker | undefined {
    return new QuoteLock(id, async () => {
      await this.view?.abandonAndClose(true);
      goStaticURL('/quotes');
    });
  }
}
